<template>
  <div>
    <span v-if="header === 'actions'">
      <pcg-checkbox  v-if="noRegister == 0 && multiOperations" variant="normal" class="multi-checkboxes" v-model="publicationCheckbox" @input="changeStatus"></pcg-checkbox>
      <pcg-options class="action-btn" :options="pcgOptions" v-if="pcgOptions.length > 0" :text="$t('components.publication_element.actions')" />
      <pcg-comments-modal
        date-type="long"
        :current-user="currentUser"
        :comments="allComments"
        editCommentPopoverText="Edytuj komentarz"
        :modal-id="achievement.id + '-allCommentsModal'"
        v-on:commentWasAdded="commentAdded"
        :adding-comments-enabled="hasRight('add_comments')"
        :comments-list-enabled="hasRight('edit_comment') || hasRight('edit_own_comment')"
      />
      <pcg-modal
        v-if="hasRight('view_history')"
        size="lg"
        scrollable
        :modalId="achievement.id + '-historyModal'"
        :title="$t('views.publications.history.title')"
        icon="pcg-icon-comment"
        btnTitle="OK"
        :action="closeHistory"
      >
        <publication-history :publication="achievement" :history="history"/>
      </pcg-modal>
      <b-modal ref="archivePublicationConfirm"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="archivePublication"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('components.publication_element.archiving') }}</div>
        <div class="confirm-text">{{ $t('components.publication_element.archiving_confirmation') }}</div>
      </b-modal>
      <b-modal ref="restorePublicationConfirm"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="restorePublication"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
            >
        <div class="confirm-header">{{ $t('components.publication_element.restoring') }}</div>
        <div class="confirm-text">{{ $t('components.publication_element.restoring_confirmation') }}</div>
      </b-modal>
    </span>
    <span v-else-if="header === 'achievement_title'">{{ achievement.title }}</span>
    <span v-else-if="header === 'achievement_title_en'">{{ achievement_title_en }}</span>
    <span v-else-if="header === 'achievement_type'">{{ $t(publicationType) }}</span>
    <span v-else-if="header === 'achievement_year'">{{ achievement.year }}</span>
    <span v-else-if="header === 'another_contest_name'">{{ achievement.anotherContestName }}</span>
    <span v-else-if="header === 'achievement_status'"> {{ $t(`components.publication_element.statuses.${achievement.status}`) }}</span>
    <span v-else-if="header === 'achievement_status_pbn'"> {{ $t(`components.publication_element.statusesPolon.${achievement.statusPbn}`) }}</span>
    <span v-else-if="header === 'external_id'"> {{ aImport }}</span>
    <div v-else-if="header === 'fee_amount'">{{ achievement.feeAmount }}</div>
    <div v-else-if="header === 'fee_type'">
      <span v-if="achievement.feeAct">{{ $t('views.achievement.new.details.fee_act') }}</span>
      <span v-else-if="achievement.feeProject">{{ $t('views.achievement.new.details.fee_project') }}</span>
      <span v-else-if="achievement.feeOther">{{ $t('views.achievement.new.details.fee_other') }}</span>
      <span v-else-if="achievement.feeFree">{{ $t('views.achievement.new.details.fee_free') }}</span>
      <span v-else>{{ $t('general.not_applicable') }}</span>
    </div>
    <div v-else-if="header === 'contributors'">
      <template v-if="tooManyContributors">
        <transition name="fade" mode="out-in">
          <div v-if="showAll" key="1">
            <div v-for="(contributor, index) in allContributors" :key="`contributor-${achievement.id}-${contributor.id}-${index}`">
              {{ contributor.name }}
            </div>
          </div>
          <div v-if="!showAll" key="2">
            <div v-for="(contributor, index) in allContributors.slice(0, 5)" :key="`contributor-${achievement.id}-${contributor.id}-${index}`">
              {{ contributor.name }}
            </div>
          </div>
        </transition>
        <div class="my-hide" @click="changeShowAll()">
          <a v-if="showAll" href="javascript:;" :aria-label="$t('general.collapse')" :aria-expanded="!showAll.toString()">
            {{ $t('general.collapse') }}
            <i class="fas fa-angle-up"></i>
          </a>
          <a v-else href="javascript:;" :aria-label="$t('general.expand')" :aria-expanded="!showAll.toString()">
            {{ $t('general.expand') }}
            <i class="fas fa-angle-down"></i>
          </a>
        </div>
      </template>
      <template v-else>
        <div v-for="(contributor, index) in allContributors" :key="`contributor-${achievement.id}-${contributor.id}-${index}`">
          {{ contributor.name }}
        </div>
      </template>
    </div>
    <span v-else-if="header === 'conference'">
      <span v-if="achievement.publicationTypeId === 4 || achievement.publicationTypeId === 25 || achievement.conferenceMaterial">{{ conferenceMaterial }}</span>
    </span>
    <span v-else-if="header === 'contest_name'">{{ tableContestName }}</span>
    <div v-else-if="header === 'contributor_points'">
      <template v-if="tooManyContributors">
        <transition name="fade" mode="out-in">
          <div v-if="showAll" key="1">
            <div v-for="(contributor, index) in allContributors" :key="`contributor_points-${achievement.id}-${contributor.id}-${index}`">
              <span v-if="contributor.alternativePoints">{{ contributor.alternativePoints }} ({{ contributor.epoints }})</span>
              <span v-else-if="contributor.epoints">{{ contributor.epoints }}</span>
              <span v-else>{{ $t('components.publication_table_element.no_points') }}</span>
            </div>
          </div>
          <div v-if="!showAll" key="2">
            <div v-for="(contributor, index) in allContributors.slice(0, 5)" :key="`contributor_points-${achievement.id}-${contributor.id}-${index}`">
              <span v-if="contributor.alternativePoints">{{ contributor.alternativePoints }} ({{ contributor.epoints }})</span>
              <span v-else-if="contributor.epoints">{{ contributor.epoints }}</span>
              <span v-else>{{ $t('components.publication_table_element.no_points') }}</span>
            </div>
          </div>
        </transition>
        <div class="my-hide" @click="changeShowAll()">
          <a v-if="showAll" href="javascript:;" :aria-label="$t('general.collapse')" :aria-expanded="!showAll.toString()">
            {{ $t('general.collapse') }}
            <i class="fas fa-angle-up"></i>
          </a>
          <a v-else href="javascript:;" :aria-label="$t('general.expand')" :aria-expanded="!showAll.toString()">
            {{ $t('general.expand') }}
            <i class="fas fa-angle-down"></i>
          </a>
        </div>
      </template>
      <template v-else>
        <div v-for="(contributor, index) in allContributors" :key="`contributor_points-${achievement.id}-${contributor.id}-${index}`">
          <span v-if="contributor.alternativePoints">{{ contributor.alternativePoints }} ({{ contributor.epoints }})</span>
          <span v-else-if="contributor.epoints">{{ contributor.epoints }}</span>
          <span v-else>{{ $t('components.publication_table_element.no_points') }}</span>
        </div>
      </template>
    </div>
    <div v-else-if="header === 'contributor_part'">
      <template v-if="tooManyContributors">
        <transition name="fade" mode="out-in">
          <div v-if="showAll" key="1">
            <div v-for="(contributor, index) in allContributors" :key="`contributor_part-${achievement.id}-${contributor.id}-${index}`">
              {{ contributor.part }}
            </div>
          </div>
          <div v-if="!showAll" key="2">
            <div v-for="(contributor, index) in allContributors.slice(0, 5)" :key="`contributor_part-${achievement.id}-${contributor.id}-${index}`">
              {{ contributor.part }}
            </div>
          </div>
        </transition>
        <div class="my-hide" @click="changeShowAll()">
          <a v-if="showAll" href="javascript:;" :aria-label="$t('general.collapse')" :aria-expanded="!showAll.toString()">
            {{ $t('general.collapse') }}
            <i class="fas fa-angle-up"></i>
          </a>
          <a v-else href="javascript:;" :aria-label="$t('general.expand')" :aria-expanded="!showAll.toString()">
            {{ $t('general.expand') }}
            <i class="fas fa-angle-down"></i>
          </a>
        </div>
      </template>
      <template v-else>
        <div v-for="(contributor, index) in allContributors" :key="`contributor_part-${achievement.id}-${contributor.id}-${index}`">
          {{ contributor.part }}
        </div>
      </template>
    </div>
    <div v-else-if="header === 'contributor_discipline'">
      <template v-if="tooManyContributors">
        <transition name="fade" mode="out-in">
          <div v-if="showAll" key="1">
            <div v-for="(contributor, index) in allContributors" :key="`contributor_discipline-${achievement.id}-${contributor.id}-${index}`">
              {{ contributorDisciplineName(contributor) }}
            </div>
          </div>
          <div v-if="!showAll" key="2">
            <div v-for="(contributor, index) in allContributors.slice(0, 5)" :key="`contributor_discipline-${achievement.id}-${contributor.id}-${index}`">
              {{ contributorDisciplineName(contributor) }}
            </div>
          </div>
        </transition>
        <div class="my-hide" @click="changeShowAll()">
          <a v-if="showAll" href="javascript:;" :aria-label="$t('general.collapse')" :aria-expanded="!showAll.toString()">
            {{ $t('general.collapse') }}
            <i class="fas fa-angle-up"></i>
          </a>
          <a v-else href="javascript:;" :aria-label="$t('general.expand')" :aria-expanded="!showAll.toString()">
            {{ $t('general.expand') }}
            <i class="fas fa-angle-down"></i>
          </a>
        </div>
      </template>
      <template v-else>
        <div v-for="(contributor, index) in allContributors" :key="`contributor_discipline-${achievement.id}-${contributor.id}-${index}`">
          {{ contributorDisciplineName(contributor) }}
        </div>
      </template>
    </div>
    <div v-else-if="header === 'contributor_evaluation'">
      <template v-if="tooManyContributors">
        <transition name="fade" mode="out-in">
          <div v-if="showAll" key="1">
            <div v-for="(contributor, index) in allContributors" :key="`contributor_evaluation-${achievement.id}-${contributor.id}-${index}`">
              <span v-if="contributor.submitted">{{ $t('views.orcid_publications.evaluation_agreement') }}</span>
              <span v-else>{{ $t('components.publication_table_element.no_evaluation') }}</span>
            </div>
          </div>
          <div v-if="!showAll" key="2">
            <div v-for="(contributor, index) in allContributors.slice(0, 5)" :key="`contributor_evaluation-${achievement.id}-${contributor.id}-${index}`">
              <span v-if="contributor.submitted">{{ $t('views.orcid_publications.evaluation_agreement') }}</span>
              <span v-else>{{ $t('components.publication_table_element.no_evaluation') }}</span>
            </div>
          </div>
        </transition>
        <div class="my-hide" @click="changeShowAll()">
          <a v-if="showAll" href="javascript:;" :aria-label="$t('general.collapse')" :aria-expanded="!showAll.toString()">
            {{ $t('general.collapse') }}
            <i class="fas fa-angle-up"></i>
          </a>
          <a v-else href="javascript:;" :aria-label="$t('general.expand')" :aria-expanded="!showAll.toString()">
            {{ $t('general.expand') }}
            <i class="fas fa-angle-down"></i>
          </a>
        </div>
      </template>
      <template v-else>
        <div v-for="(contributor, index) in allContributors" :key="`contributor_evaluation-${achievement.id}-${contributor.id}-${index}`">
          <span v-if="contributor.submitted">{{ $t('views.orcid_publications.evaluation_agreement') }}</span>
          <span v-else>{{ $t('components.publication_table_element.no_evaluation') }}</span>
        </div>
      </template>
    </div>
    <div v-else-if="header === 'contributor_faculty'">
      <template v-if="tooManyContributors">
        <transition name="fade" mode="out-in">
          <div v-if="showAll" key="1">
            <div v-for="(contributor, index) in allContributors" :key="`contributor_faculty-${achievement.id}-${contributor.id}-${index}`">
              {{ contributorFacultyName(contributor) }}
            </div>
          </div>
          <div v-if="!showAll" key="2">
            <div v-for="(contributor, index) in allContributors.slice(0, 5)" :key="`contributor_faculty-${achievement.id}-${contributor.id}-${index}`">
              {{ contributorFacultyName(contributor) }}
            </div>
          </div>
        </transition>
        <div class="my-hide" @click="changeShowAll()">
          <a v-if="showAll" href="javascript:;" :aria-label="$t('general.collapse')" :aria-expanded="!showAll.toString()">
            {{ $t('general.collapse') }}
            <i class="fas fa-angle-up"></i>
          </a>
          <a v-else href="javascript:;" :aria-label="$t('general.expand')" :aria-expanded="!showAll.toString()">
            {{ $t('general.expand') }}
            <i class="fas fa-angle-down"></i>
          </a>
        </div>
      </template>
      <template v-else>
        <div v-for="(contributor, index) in allContributors" :key="`contributor_faculty-${achievement.id}-${contributor.id}-${index}`">
          {{ contributorFacultyName(contributor) }}
        </div>
      </template>
    </div>
    <div v-else-if="header === 'contributor_doctoral_school'">
      <template v-if="tooManyContributors">
        <transition name="fade" mode="out-in">
          <div v-if="showAll" key="1">
            <div v-for="(contributor, index) in allContributors" :key="`contributor_doctoral_school-${achievement.id}-${contributor.id}-${index}`">
              {{ contributorDoctoralSchool(contributor) }}
            </div>
          </div>
          <div v-if="!showAll" key="2">
            <div v-for="(contributor, index) in allContributors.slice(0, 5)" :key="`contributor_doctoral_school-${achievement.id}-${contributor.id}-${index}`">
              {{ contributorDoctoralSchool(contributor) }}
            </div>
          </div>
        </transition>
        <div class="my-hide" @click="changeShowAll()">
          <a v-if="showAll" href="javascript:;" :aria-label="$t('general.collapse')" :aria-expanded="!showAll.toString()">
            {{ $t('general.collapse') }}
            <i class="fas fa-angle-up"></i>
          </a>
          <a v-else href="javascript:;" :aria-label="$t('general.expand')" :aria-expanded="!showAll.toString()">
            {{ $t('general.expand') }}
            <i class="fas fa-angle-down"></i>
          </a>
        </div>
      </template>
      <template v-else>
        <div v-for="(contributor, index) in allContributors" :key="`contributor_doctoral_school-${achievement.id}-${contributor.id}-${index}`">
          {{ contributorDoctoralSchool(contributor) }}
        </div>
      </template>
    </div>
    <div v-else-if="header === 'ministerial_list'">
      <span v-if="achievement.publisher && achievement.publisher.id && !achievement.publisher.academyId">{{ $t('general.yes') }}</span>
      <span v-else>{{ $t('general.no') }}</span>
    </div>
    <div v-else-if="header === 'contributor_name_and_id'">
      <template v-if="tooManyContributors">
        <transition name="fade" mode="out-in">
          <div v-if="showAll" key="1">
            <div v-for="(contributor, index) in allContributors" :key="`contributor_name_and_sap-${achievement.id}-${contributor.id}-${index}`">
              <span>{{ contributor.name }}</span>
              <span v-if="contributor.sourceId"> ({{ contributor.sourceId }})</span>
            </div>
          </div>
          <div v-if="!showAll" key="2">
            <div v-for="(contributor, index) in allContributors.slice(0, 5)" :key="`contributor_name_and_sap-${achievement.id}-${contributor.id}-${index}`">
              <span>{{ contributor.name }}</span>
              <span v-if="contributor.sourceId"> ({{ contributor.sourceId }})</span>
            </div>
          </div>
        </transition>
        <div class="my-hide" @click="changeShowAll()">
          <a v-if="showAll" href="javascript:;" :aria-label="$t('general.collapse')" :aria-expanded="!showAll.toString()">
            {{ $t('general.collapse') }}
            <i class="fas fa-angle-up"></i>
          </a>
          <a v-else href="javascript:;" :aria-label="$t('general.expand')" :aria-expanded="!showAll.toString()">
            {{ $t('general.expand') }}
            <i class="fas fa-angle-down"></i>
          </a>
        </div>
      </template>
      <template v-else>
        <div v-for="(contributor, index) in allContributors" :key="`contributor_name_and_sap-${achievement.id}-${contributor.id}-${index}`">
          <span>{{ contributor.name }}</span>
          <span v-if="contributor.sourceId"> ({{ contributor.sourceId }})</span>
        </div>
      </template>
    </div>
    <span v-else-if="header === 'country_amount'">{{ achievement.nationalFunds }}</span>
    <div v-else-if="header === 'created_at'">
      {{ $d(Date.parse(achievement.createdAt), 'long') }}
    </div>
    <div v-else-if="header === 'discipline'">
      <div v-for="discipline in achievementDisciplines" :key="`discipline-${achievement.id}-${discipline.id}`">
        {{ disciplineName(discipline.disciplineId) }}
      </div>
    </div>
    <span v-else-if="header === 'doi'">{{ isDoi }}</span>
    <span v-else-if="hasRight('view_publication_flags') && (header === 'flags')">{{ tableFlagNames }}</span>
    <span v-else-if="header === 'foreign_amount'">{{ achievement.foreignFunds }}</span>
    <span v-else-if="header === 'financing_institution_different_name'">{{ otherFinancingInstitutionNames }}</span>
    <span v-else-if="header === 'financing_institution_different'">{{ otherFinancingInstitutions }}</span>
    <span v-else-if="header === 'issn'">{{ isIssn }}</span>
    <span v-else-if="header === 'isbn'">{{ isIsbn }}</span>
    <span v-else-if="header === 'periodical'">
      <span v-if="achievement.publicationTypeId === 20">{{ periodical }}</span>
    </span>
    <span v-else-if="header === 'highest_percentile'">
      <span v-if="achievement.publicationTypeId === 20">{{ achievement.publisher.percentile }}</span>
    </span>
    <span v-else-if="header === 'citations'">
      <span v-if="achievement.publicationTypeId === 20">{{ achievement.publisher.citations }}</span>
    </span>
    <span v-else-if="header === 'cite_score'">
      <span v-if="achievement.publicationTypeId === 20">{{ achievement.publisher.citeScore }}</span>
    </span>
    <span v-else-if="header === 'cited'">
      <span v-if="achievement.publicationTypeId === 20">{{ achievement.publisher.cited }}</span>
    </span>
    <span v-else-if="header === 'documents'">
      <span v-if="achievement.publicationTypeId === 20">{{ achievement.publisher.documents }}</span>
    </span>
    <span v-else-if="header === 'snip'">
      <span v-if="achievement.publicationTypeId === 20">{{ achievement.publisher.snip }}</span>
    </span>
    <span v-else-if="header === 'sjr'">
      <span v-if="achievement.publicationTypeId === 20">{{ achievement.publisher.sjr }}</span>
    </span>
    <div v-else-if="header === 'achievement_source'">
      <span v-if="achievement.mainSource">
        <span v-if="achievement.mainSource === 'orcid'">ORCiD</span>
        <span v-if="achievement.mainSource === 'pbn'">PBN</span>
        <span v-if="achievement.mainSource === 'polon'">POL-on</span>
        <span v-if="achievement.mainSource === 'self'">{{ $t('views.achievement.list.record') }}</span>
        <span v-if="achievement.mainSource === 'api'">API</span>
      </span>
      <span v-else>
        {{ $t('views.achievement.list.record') }}
      </span>
    </div>
    <div v-else-if="header === 'duplicates'">
      <div v-for="duplicate in achievement.duplicates" :key="duplicate.id">
        {{ $t('components.publication_table_element.duplicate_id') }} {{ duplicate.id }}
      </div>
    </div>
    <span v-else-if="header === 'principal_name'">{{ achievement.principalName }}</span>
    <div v-else-if="header === 'abstract'" style="max-width: 20rem; overflow-wrap: break-word; word-break: break-word; white-space: break-spaces;">
      {{ achievement.abstract ? achievement.abstract.substring(0,30) : ''}}
    </div>
    <div v-else-if="header === 'project_abstract_en'" style="max-width: 20rem; overflow-wrap: break-word; word-break: break-word; white-space: break-spaces;">
      {{ achievement.projectAbstractEn ? achievement.projectAbstractEn.substring(0,30) : '' }}
    </div>
    <span v-else-if="header === 'project_acronym'">{{ achievement.projectAcronym }}</span>
    <span v-else-if="header === 'project_end'">{{ achievement.projectEnd }}</span>
    <span v-else-if="header === 'project_individual'">{{ tf(achievement.projectIndividual) }}</span>
    <div v-else-if="header === 'project_manager'">
      <div v-for="manager in achievement.projectManagers" :key="`project_discipline-${achievement.id}-${manager.id}`">
        {{ manager.author.name }}
      </div>
    </div>
    <span v-else-if="header === 'project_number'">{{ achievement.projectNumber }}</span>
    <span v-else-if="header === 'project_participants'">{{ tableProjectParticipants }}</span>
    <span v-else-if="header === 'project_priority_name'">{{ achievement.projectPriorityName }}</span>
    <span v-else-if="header === 'project_start'">{{ achievement.projectStart }}</span>
    <span v-else-if="header === 'project_types'">{{ projectTypeNames }}</span>
    <span v-else-if="header === 'publisher'">
      <span v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 21">{{ periodical }}</span>
    </span>
    <div v-else-if="header === 'related_projects'">
      <div v-for="project in achievement.relatedProjects" :key="`related-project-${achievement.id}-${project.id}`">
        <span>{{ project.name }}</span>
      </div>
    </div>
    <span v-else-if="header === 'source_points'">{{ achievement.sourcePoints }}</span>
    <span v-else-if="header === 'total_amount'">{{ (achievement.publicationTypeId === 7 || achievement.publicationTypeId === 32) ? achievement.totalFunds : achievement.amount }}</span>
    <div v-else-if="header === 'updated_at'">
      {{ achievement.lastModifiedAt ? $d(Date.parse(achievement.lastModifiedAt), 'long') : $d(Date.parse(achievement.updatedAt), 'long') }}
    </div>
    <span v-else-if="header === 'external_link'">{{ achievement.externalLink }}</span>
    <span v-else-if="header === 'export_error'" class="long-text">{{ achievement.exportError }}</span>
  </div>
</template>

<script>
import hasRight from '../mixins/has_right'
import { mapGetters } from 'vuex'
import ApiComments from '../api/comments'
import ApiPublications from '../api/publications'
import PublicationHistory from './PublicationHistory'
import unexpectedError from '../mixins/unexpected_error'
import tf from '../mixins/tf'

export default {
  name: 'PublicationTableElement',
  components: { PublicationHistory },
  mixins: [hasRight, tf, unexpectedError],
  props: {
    achievement: Object,
    contestOptions: Array,
    financingInstitutions: Array,
    flags: Array,
    projectTypes: Array,
    header: String,
    owner: {
      type: Boolean,
      default: false
    },
    multiCheckbox: {
      type: Boolean,
      default: false
    },
    multiSelect: String,
    unselectedPublications: Array,
    selectedPublications: Array,
    multiOperations: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      appKind: '',
      noRegister: 1,
      noRepo: 1,
      roles: [],
      currentUser: {
        id: '',
        author: '',
        entity: '',
        avatar: '',
        role: ''
      },
      pcgOptions: [],
      publicationCheckbox: false,
      allComments: [],
      history: null,
      allContributors: [],
      showAll: false
    }
  },
  created () {
  },
  mounted () {
    this.setStatus()
    this.getAllContributors()
  },
  computed: {
    ...mapGetters('disciplines', ['disciplines']),
    achievement_title_en () {
      if (this.achievement.projectTitleEn) {
        return this.achievement.projectTitleEn
      } else {
        return this.achievement.otherTitles[0] ? this.achievement.otherTitles[0].value : ''
      }
    },
    achievementDisciplines () {
      if (this.achievement.projectDisciplines && this.achievement.projectDisciplines.length > 0) {
        return this.achievement.projectDisciplines
      } else if (this.achievement.serviceDisciplines && this.achievement.serviceDisciplines.length > 0) {
        return this.achievement.serviceDisciplines
      } else {
        return []
      }
    },
    publicationType () {
      const pubId = this.achievement.publicationTypeId
      let result = ''
      switch (pubId) {
        case 3:
          result = 'monograph'
          break
        case 4:
        case 25:
          result = 'conference'
          break
        case 7:
          result = 'patent'
          break
        case 19:
          result = 'chapter'
          break
        case 20:
          result = 'article'
          break
        case 21:
          result = 'monograph_redaction'
          break
        case 30:
          result = 'service'
          break
        case 32:
          result = 'project'
          break
        case 35:
          result = 'artistic_activity'
          break
        case 45:
          result = 'environmental_impact'
          break
        default:
          result = 'other'
      }
      return 'views.publications.types.' + result
    },
    isDoi () {
      if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
        for (let i = 0; i < this.achievement.sources.length; i++) {
          if (this.achievement.sources[i].sourceName === 'doi') {
            return this.achievement.sources[i].sourceId
          }
        }
      }
      return null
    },
    isIsbn () {
      if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
        for (let i = 0; i < this.achievement.sources.length; i++) {
          if (this.achievement.sources[i].sourceName === 'isbn') {
            return this.achievement.sources[i].sourceId
          }
        }
      }
      return null
    },
    isIssn () {
      if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
        for (let i = 0; i < this.achievement.sources.length; i++) {
          if (this.achievement.sources[i].sourceName === 'issn') {
            return this.achievement.sources[i].sourceId
          }
        }
      }
      return null
    },
    periodical () {
      let result = ''
      if (this.achievement.publisher.name) {
        result = this.achievement.publisher.name
      } else {
        result = this.achievement.publisherTitle || ''
      }
      return result
    },
    conferenceMaterial () {
      let result = ''
      result = this.achievement.publisherConference.name ? this.achievement.publisherConference.name : ''
      return result
    },
    otherFinancingInstitutions () {
      const result = []
      this.achievement.projectFinancings?.forEach((pf) => {
        if (pf.otherFinancingInstitution.value === null) {
          result.push('Nie')
        } else {
          result.push('Tak')
        }
      })
      return result.join(', ')
    },
    otherFinancingInstitutionNames () {
      const result = []
      let fi = null
      this.achievement.projectFinancings?.forEach((pf) => {
        if (pf.otherFinancingInstitution.value === null) {
          fi = this.financingInstitutions.find((f) => {
            return f.id === pf.id
          })
          result.push(fi.text)
        } else {
          result.push(pf.otherFinancingInstitution.value)
        }
      })
      return result.join(', ')
    },
    tableContestName () {
      const found = this.contestOptions.find(element => element.id === this.achievement.contestName)
      if (found) {
        return found.text
      } else {
        return ''
      }
    },
    tableFlagNames () {
      const result = []
      this.achievement.flags.forEach((flagId) => {
        const found = this.flags.find(element => element.id === flagId)
        if (found) {
          result.push(found.text)
        }
      })
      return result.join(', ')
    },
    projectTypeNames () {
      const result = []
      if (this.achievement.projectTypes) {
        this.achievement.projectTypes.forEach((ptId) => {
          const found = this.projectTypes.find(element => element.id === ptId)
          if (found) {
            result.push(found.text)
          }
        })
      }
      return result.join(', ')
    },
    tableProjectParticipants () {
      const result = []
      if (this.achievement.projectParticipants) {
        this.achievement.projectParticipants.forEach((pt) => {
          result.push(pt.author.name)
        })
      }
      return result.join(', ')
    },
    tooManyContributors () {
      if (this.allContributors && this.allContributors.length > 5) {
        return true
      } else {
        return false
      }
    },
    aImport: {
      get () {
        let source
        if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
          source = this.achievement.sources.find(s => s.sourceName === 'api')
          if (source) {
            return source.sourceId
          } else {
            return null
          }
        }
        return null
      }
    }
  },
  beforeMount () {
    this.roles = this.$store.getters['auth/roles']
    this.currentUser = {
      id: this.$store.getters['auth/id'],
      author: this.$store.getters['auth/fullName'],
      entity: '',
      avatar: this.$store.getters['auth/avatar'],
      role: this.$store.getters['auth/currentRole']
    }
    this.appKind = process.env.APP_KIND
    this.noRegister = process.env.NO_REGISTER
    this.noRepo = process.env.NO_REPO
    // eslint-disable-next-line eqeqeq
    if (this.noRegister == 0) {
      if (this.hasRight('edit_achievement') || (this.owner && this.hasRight('edit_own_achievement'))) {
        this.pcgOptions.push({
          text: 'Edytuj',
          action: () => { this.editPublication() }
        })
      }
      if (this.hasRight('view_comments')) {
        this.pcgOptions.push({
          text: 'Komentarze',
          action: () => { this.showComments() }
        })
      }
      if (this.hasRight('view_history')) {
        this.pcgOptions.push({
          text: 'Historia publikacji',
          action: () => {
            this.getHistory()
          }
        })
      }
      if ((this.currentUser.role === 'pracownik' || this.currentUser.role === 'analityknaukowy') && this.achievement.status !== 6) {
        this.pcgOptions.push({
          text: 'Archiwizuj',
          action: () => { this.archivePublicationsModal() }
        })
      }
      if ((this.currentUser.role === 'pracownik' || this.currentUser.role === 'analityknaukowy') && this.achievement.status === 6) {
        this.pcgOptions.push({
          text: 'Przywróć',
          action: () => { this.restorePublicationsModal() }
        })
      }
    }
  },
  methods: {
    editPublication () {
      this.$router.push({ name: 'achievement_edit_path', params: { id: this.achievement.id } })
    },
    showComments () {
      ApiComments.getComments(this.achievement.id)
        .then(result => {
          this.allComments = result.data.datas
          this.allComments.forEach(comment => {
            if (comment.date === comment.editedDate) {
              comment.editedDate = null
            }
            comment.date = this.$d(Date.parse(comment.date), 'long')
            comment.editedDate = comment.editedDate ? this.$d(Date.parse(comment.editedDate), 'long') : null
          })
          this.$bvModal.show(this.achievement.id + '-allCommentsModal')
        })
        .catch(error => {
          console.log(error)
        })
    },
    commentAdded (comment) {
      ApiComments.createComment(this.achievement.id, comment)
        .then(result => {
          ApiComments.getComments(this.achievement.id)
            .then(result => {
              this.allComments = result.data.datas
              this.allComments.forEach(comment => {
                if (comment.date === comment.editedDate) {
                  comment.editedDate = null
                }
                comment.date = this.$d(Date.parse(comment.date), 'long')
                comment.editedDate = comment.editedDate ? this.$d(Date.parse(comment.editedDate), 'long') : null
              })
              this.$bvModal.show(this.achievement.id + '-allCommentsModal')
            })
        })
        .catch(error => {
          console.log(error)
        })
    },
    getHistory () {
      ApiPublications.getHistory(this.achievement.id)
        .then(result => {
          this.history = result.data.datas
          this.$bvModal.show(this.achievement.id + '-historyModal')
        })
        .catch(error => {
          console.log(error)
        })
    },
    closeHistory () {
      this.$bvModal.hide(this.achievement.id + '-historyModal')
    },
    archivePublication () {
      ApiPublications.archivePublications([this.achievement.id], [])
        .then(result => {
          this.$emit('changePublicationStatus')
        })
        .catch(error => {
          console.log(error)
          this.unexpectedError()
        })
    },
    restorePublication () {
      ApiPublications.changeStatus(this.achievement.id, 0)
        .then(result => {
          this.$emit('changePublicationStatus')
        })
        .catch(error => {
          console.log(error)
          this.unexpectedError()
        })
    },
    archivePublicationsModal () {
      this.$refs.archivePublicationConfirm.show()
    },
    restorePublicationsModal () {
      this.$refs.restorePublicationConfirm.show()
    },
    changeStatus () {
      if (this.publicationCheckbox) {
        this.$emit('removeUnselected', this.achievement.id)
      } else {
        this.$emit('addUnselected', this.achievement.id)
      }
    },
    setStatus () {
      if (this.multiCheckbox && this.multiSelect) {
        this.publicationCheckbox = true
      } else if (!this.multiCheckbox) {
        this.publicationCheckbox = false
      }
      if (this.unselectedPublications && this.unselectedPublications.includes(this.achievement.id)) {
        this.publicationCheckbox = false
      }
      if (this.selectedPublications && this.selectedPublications.includes(this.achievement.id)) {
        this.publicationCheckbox = true
      }
    },
    contributorDisciplineName (author) {
      if (!author.disciplineId) {
        return this.$t('components.publication_table_element.no_discipline')
      }
      const idx = this.disciplines.findIndex(b => b.id === author.disciplineId.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.disciplines[idx].text
        } else {
          return this.disciplines[idx].text_en
        }
      } else {
        return this.$t('components.publication_table_element.no_discipline')
      }
    },
    contributorFacultyName (author) {
      if (!author.faculties || (author.faculties && author.faculties.length === 0)) {
        return this.$t('components.publication_table_element.no_faculties')
      }
      let facultyText = ''
      author.faculties.forEach(faculty => {
        if (facultyText) {
          facultyText = facultyText + ', ' + faculty.parents + ' -> ' + faculty.name
        } else {
          facultyText = faculty.parents + ' -> ' + faculty.name
        }
      })
      return facultyText
    },
    contributorDoctoralSchool (author) {
      if (!author.doctoralSchoolCode && author.authorType !== 3) {
        return this.$t('components.publication_table_element.no_doctoral_school_code_required')
      } else if (!author.doctoralSchoolCode && author.authorType === 3) {
        return this.$t('components.publication_table_element.no_doctoral_school_code')
      } else
        return author.doctoralSchoolCode
    },
    disciplineName (id) {
      if (!id) {
        return this.$t('components.publication_table_element.no_discipline')
      }
      const idx = this.disciplines.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.disciplines[idx].text
        } else {
          return this.disciplines[idx].text_en
        }
      } else {
        return this.$t('components.publication_table_element.no_discipline')
      }
    },
    getAllContributors () {
      this.allContributors = []
      this.achievement.authors.forEach((a) => {
        this.allContributors.push(a)
      })
      this.achievement.redactors.forEach((r) => {
        this.allContributors.push(r)
      })
    },
    changeShowAll () {
      this.showAll = !this.showAll
    }
  },
  watch: {
    multiSelect: {
      deep: true,
      handler () {
        this.setStatus()
      }
    },
    multiCheckbox: {
      deep: true,
      handler () {
        this.setStatus()
      }
    },
    'achievement.authors': {
      deep: true,
      handler () {
        this.getAllContributors()
      }
    },
    'achievement.redactors': {
      deep: true,
      handler () {
        this.getAllContributors()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../assets/stylesheets/vars';
  .multi-checkboxes {
    display: inline-block;
    /deep/ .pcg-checkbox-container {
      margin-bottom: 0;
      margin-right: 0;
    }
  }
  .action-btn {
    height: 35px;
  }
  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }
  .confirm-text {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }
  .my-hide {
    color: $main-color;
    cursor: pointer;
    a {
      color: unset;
      text-decoration: none;
      &:hover {
        color: unset;
        text-decoration: none;
      }
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .long-text {
    display: inline-block;
    min-width: 300px;
    max-width: 400px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }
</style>

<style lang="scss" >
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .confirm-header {
      color: $pcg-gray;
    }
    .confirm-text {
      color: $pcg-gray;
    }
    .action-btn {
      /deep/ .pcg-button {
        background: $pcg-the-lightest-gray !important;
        g {
          fill: rgb(0, 153, 65) !important;
        }
      }
    }
    .my-hide {
      color: $main-color;
    }
  }
</style>
