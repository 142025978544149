import { render, staticRenderFns } from "./PublicationTableElement.vue?vue&type=template&id=f06b1f06&scoped=true&"
import script from "./PublicationTableElement.vue?vue&type=script&lang=js&"
export * from "./PublicationTableElement.vue?vue&type=script&lang=js&"
import style0 from "./PublicationTableElement.vue?vue&type=style&index=0&id=f06b1f06&scoped=true&lang=scss&"
import style1 from "./PublicationTableElement.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f06b1f06",
  null
  
)

export default component.exports