export const components = {
  achievement: {
    added_by: 'Źródło:',
    authors: 'Autorzy',
    availability: 'Dostępność:',
    conference_material: 'Materiał konferencyjny',
    created_at: 'Utworzono',
    databases: 'Bazy:',
    discipline: 'Dyscyplina',
    duplicate: 'Duplikat',
    evaluation: 'Ewaluacja',
    monograph: 'Monografia',
    not_submited: 'Nie zgłoszono do ewaluacji',
    register: 'Ewidencja',
    submited: 'Zgłoszono do ewaluacji',
    updated_at: 'Zaktualizowano'
  },
  dn_discipline_statements: {
    discipline: 'Dyscyplina',
    discipline_participation: 'Udział dyscypliny',
    dissertation_from: 'Szkoła doktorska od',
    dissertation_to: 'Szkoła doktorska do',
    exact_date: 'Dokładna data',
    field: 'Dziedzina',
    is_leading: 'Dyscyplina wiodąca',
    overwriting_statements: 'Nadpisywanie oświadczeń',
    overwriting_statements_confirmation: 'Dodanie tego oświadczenia spowoduje nadpisanie niektórych poprzednich oświadczeń. Czy na pewno chcesz nadpisać następujące oświadczenia?',
    validity_from: 'Ważność od',
    validity_to: 'Ważność do'
  },
  dn_discipline_user: {
    discipline_user: 'Pracownik dyscypliny',
    edit_user: 'Edytuj pracowników dyscypliny',
    employee: 'Pracownik dyscypliny:'
  },
  dn_dissertation: {
    application: 'Zgłoszenie do liczby N',
    change: 'zmień',
    changed: 'Zmieniono',
    choose: 'wybierz',
    confirm: 'potwierdź',
    choose_discipline: 'Wybierz dyscyplinę i okres',
    choose_disciplines: 'Wybierz dyscypliny',
    deadline: ' | Pozostał 1 miesiąc na zgłoszenie osiągnięcia | Pozostały {count} miesiące na zgłoszenie osiągnięcia | Pozostało {count} miesięcy na zgłoszenie osiągnięcia',
    discipline_selection: 'Dyscyplina i okres',
    edit_n: 'edytuj N',
    evaluation: 'Zgłoszenie do ewaluacji',
    fill_statement: 'Brak oświadczenia',
    header: 'Szkoła doktorska',
    header_tooltip: 'Dyscyplina rozprawy doktorskiej oraz okres uczestnictwa doktoranta w szkole doktorskiej',
    no_discipline: 'Nie wybrano żadnej dyscypliny',
    remove: 'usuń',
    remove_dissertation: 'Usuwanie',
    remove_dissertation_confirmation: 'Czy na pewno chcesz usunąć obecność w szkole doktorskiej?'
  },
  dn_employments: {
    absence: 'Urlop ponad dwa lata',
    change: 'zmień',
    confirmed: 'Potwierdzony',
    dissertation: 'Rozprawa doktorska',
    dissertation_tooltip: 'Doktorant szkoły doktorskiej, który w okresie ewaluacji przygotowywał rozprawę doktorską w dyscyplinie naukowej albo w dziedzinie nauki, do której należy dana dyscyplina naukowa',
    doctorant_in_school: 'Doktorant w szkole doktorskiej',
    edit_employments: 'Aby móc edytować etat musisz najpierw potwierdzić etat',
    employment_set: 'Etat potwierdzony',
    evaluation_years: 'Lata ewaluacji',
    from: 'Od:',
    full_working_time: 'ustaw pełen etat',
    header: 'Wymiar pracy',
    insufficient_time: 'Zatrudnienie poniżej roku',
    insufficient_time_current_tooltip: 'Jeżeli pracownik zatrudniony był poniżej 12 miesięcy i zatrudnienie zakończyło się przed 31 grudnia roku ewaluowanego, to pracownik ten nie będzie zaliczony do N.',
    insufficient_time_tooltip: 'Pracownik zatrudniony w sposób ciągły poniżej 12 miesięcy w okresie ewaluacji, na stanowisku do którego obowiązków należy prowadzenie działalności naukowej. Dotyczy pracowników niezaliczonych do liczby N w okresie ewaluacji.',
    ignore_minuses: 'Wyłączenie z restrykcji',
    ignore_minuses_tooltip: 'Pracownik w okresie objętym ewaluacją:\n1) korzystał z urlopu bezpłatnego,\n2) korzystał z urlopu dla poratowania zdrowia,\n3) korzystał z któregokolwiek z urlopów związanych z rodzicielstwem, określonych w przepisach ustawy z dnia 26 czerwca 1974 r. - Kodeks pracy (Dz. U. z 2018 r. poz. 917, zpóźn. zm.),\n4) przebywał na zasiłku chorobowym lub pobierał świadczenie rehabilitacyjne, zgodnie z przepisami ustawy z dnia 25 czerwca 1999 r. o świadczeniach pieniężnych z ubezpieczenia społecznego w razie choroby i macierzyństwa (Dz. U. z 2017 r. poz. 1368, z późn. zm.)\n\n- łącznie przez co najmniej 24 miesiące lub przez co najmniej połowę okresu zatrudnienia w ewaluowanym podmiocie.\n\n5) w okresie objętym ewaluacją był zatrudniony w podmiocie na stanowisku, do którego obowiązków należy prowadzenie działalności naukowej, poniżej 24 miesiące (36 miesięcy w przypadku ewaluacji 2022) \n6) złożył oświadczenie/oświadczenia o dyscyplinach obejmujące w okresie objętym ewaluacją, czas krótszy niż 24 miesiące (36 miesięcy w przypadku ewaluacji 2022)"',
    modify: 'Modyfikuj',
    months: '0 miesięcy | 1 miesiąc | {count} miesiące | {count} miesięcy',
    not_enough: 'Wyjątek dla minusów',
    not_enough_text: 'Pracownik w okresie objętym ewaluacją był zatrudniony poniżej 2 lat (3 lat w przypadku ewaluacji 2021) lub ponad 2 lata przebywał na urlopach i zwolnieniach.',
    no_required_working_time: 'Brak wymaganych etatów',
    other_statement: 'N w innym podmiocie',
    other_statement_tooltip: 'Pracownik złożył w okresie objętym ewaluacją oświadczenie o zaliczeniu do liczby N w innym podmiocie.',
    position: 'Rodzaj stanowiska',
    reset_working_time: 'zeruj etat',
    to: 'Do:',
    vacation: 'Urlop',
    working_time: 'Etat',
    year: 'Rok'
  },
  dn_evaluation: {
    and: 'oraz',
    article: 'Artykuł',
    authors: '0 autorów | 1 autor | {count} autorów',
    choose_disciplines: 'Wybierz dyscypliny',
    choose_doctoral_school: 'Wybierz okres i dyscyplinę',
    conference: 'Konferencja',
    count_slots: 'Sloty:',
    creators: '0 twórców | 1 twórca | {count} twórców',
    empty_discipline: 'Brak oświadczenia o dyscyplinach',
    empty_doctoral_school: 'Brak szkoły doktorskiej',
    empty_publications: 'Tu pojawią się osiąnięcia',
    estimated_result: 'Szacunkowy wynik',
    estimated_result_text: 'Estymacja wyniku przy zapełnieniu 100% slotów na podstawie aktualnego wyniku punktowego oraz zapełnienia slotów',
    estimated_result_text_artistic: 'Estymacja wyniku przy zapełnieniu 100% limitu na podstawie aktualnego wyniku punktowego oraz wypełnienia limitu',
    filling_limits: 'wypełnienie\nlimitu',
    filling_percent: 'wypełnienia',
    filling_slots: 'wypełnienie\nslotów',
    for_discipline: 'dla dyscypliny',
    from: 'z',
    header: 'Ewaluacja',
    hide_others: 'Schowaj pozostałe',
    included_slots: 'W trakcie ewaluacji uwzględnione zostanie 0 slotów | W trakcie ewaluacji uwzględniony zostanie 1 slot | W trakcie ewaluacji uwzględnionych zostanie {count} sloty | W trakcie ewaluacji uwzględnionych zostanie {count} slotów | W trakcie ewaluacji uwzględnione zostanie {count} slota',
    monograph: 'Monografia',
    no_achievements: 'Brak osiągnięć',
    not_assigned_publications: ' | {count} osiągnięcie nieprzypisane do dyscyplin | {count} osiągnięcia nieprzypisane do dyscyplin | {count} osiągnięć nieprzypisanych do dyscyplin',
    not_evaluated: 'Dyscyplina nie podlega ewaluacji',
    no_publications: 'Brak osiągnięć w obrębie tej dyscypliny',
    not_submitted_for_evaluation: ' | oraz 1 niezgłoszone do ewaluacji | oraz {count} niezgłoszone do ewaluacji | oraz {count} niezgłoszonych do ewaluacji',
    patent: 'Patent',
    patents: 'Patenty',
    periodical: 'Czasopismo:',
    points: 'punktów | punkt | punkty | punktów | punktu',
    position_points: 'Pozycja na tle maksymalnego wyniku w dyscyplinie',
    publications: 'Osiągnięcia',
    publisher: 'Wydawnictwo:',
    score: 'Wynik',
    scoring: 'Punktacja',
    scoring_upper_percent: 'Uzyskana punktacja sytuuje cię w górnych {count}% pracowników naukowych naszej uczelni',
    set_discipline: 'Przypisz dyscyplinę wiodącą z oświadczenia',
    set_discipline_title: 'Przypisanie dyscypliny wiodącej.',
    set_discipline_confirmation: 'Chcesz przypisać dyscyplinę wiodącą do wszystkich osiągnięć bez dyscypliny.',
    show_all: 'Ewidencja osiągnięć',
    show_others: 'Pokaż pozostałe',
    slot_text: 'Sloty',
    slots: 'slotów | slota | slotów | slotów | slota',
    slots_tooltip: 'Maksymalna suma udziałów w osiągnięciach możliwych do wykazania podczas ewaluacji w wybranej dyscyplinie.\n' +
      '\n' +
      'Limit obliczany jest na podstawie średniej wartości iloczynu wymiaru czasu pracy, według stanu na 31 grudnia każdego roku objętego ewaluacją, oraz udziału czasu pracy związanej z prowadzeniem działalności naukowej w ramach tej dyscypliny naukowej, zadeklarowanej w oświadczeniu o dyscyplinach.',
    slots_upper_percent: 'Wypełnienie slotów sytuuje cię w górnych {count}% pracowników naukowych naszej uczelni',
    start_date: 'Data rozpoczęcia:',
    with: 'z',
    working_time: 'Slot:',
    year: 'Rok',
    years: 'lat | roku | lat | lat | roku',
    your_empty_publications: 'Tu pojawią się twoje osiągnięcia',
    your_estimated_result: 'Twój szacunkowy wynik',
    your_future_estimations: 'Tu pojawią się twoje estymacje',
    your_patents: 'Twoje patenty',
    your_publications: 'Twoje osiągnięcia',
    your_score: 'Twój wynik'
  },
  dn_field: {
    add: '+ Dodaj',
    applications: 'Liczba N (średnia z lat {from}-{to})',
    change: 'zmień',
    choose: 'wybierz',
    chosen_discipline: 'Wybrana dyscyplina',
    discipline_manager: 'Kierownik dyscypliny',
    economic: 'Jednostka referencyjna: Wydział Nauk Ekonomicznych (HS1EK), kategoria naukowa: B',
    economic_text: 'Wartość referencyjna kryterium A: 150 punktów, punkt przewyższenia G: 0,2',
    edit_manager: 'Edytuj kierowników',
    filling_limits: 'Wypełnienie limitu',
    filling_slots: 'Wypełnienie slotów',
    it: 'Jednostka referencyjna: Wydział Elektroniki i Informatyki (SI1EA), kategoria naukowa: B',
    it_text: 'Wartość referencyjna kryterium A: 120 punktów, punkt przewyższenia G: 0,2',
    job_positions: 'Etaty przeliczeniowe',
    manager: 'Kierownik dyscypliny:',
    no_manager: 'Nie wybrano',
    not_enough_job_positions: 'Za mało zgłoszeń do liczby N',
    not_enough_job_positions_tooltip: 'Na podstawie Art. 265 ustęp 4. Ustawy z dnia 20 lipca 2018 r. Prawo o szkolnictwie wyższym i nauce. Liczba N jest liczona na stan 31 grudnia ostatniego roku danego okresu ewaluacyjnego.',
    number_n_last_year: 'Liczba N (rok {year}):',
    number_of_publications: 'Uwzględnione osiągnięcia:',
    people: '0 osób | 1 osoba | {count} osoby | {count} osób',
    points: 'punktów | punkt | punkty | punktów | punktu',
    points_n: 'Punkty na N',
    too_many_job_positions: 'Więcej zgłoszonych etatów niż potrzeba w zgłoszeniu do dyscypliny'
  },
  dn_file_input: {
    error_title: 'Wystąpił błąd',
    loading_title: 'Wczytywanie pliku',
    success_title: 'Plik załadowano pomyślnie',
    upload_title: 'Przeciągnij i upuść lub wybierz plik'
  },
  dn_footer: {
    achievement: 'Dorobek',
    achievements: 'osiągnięć naukowych',
    integration: 'Integracja z bazami',
    million: '1 million',
    orcid: 'ORCID oraz PBN',
    periodical_count: '30 tysięcy',
    periodicals: 'czasopism w tym wykaz ministerialny',
    researchers: 'pracowników naukowych',
    thousands: '100 tysięcy'
  },
  dn_header_navbar: {
    login_btn: 'Zaloguj się',
    register_btn: 'Zarejestruj się'
  },
  dn_layout_footer: {
    accessibility_declaration: 'Deklaracja dostępności'
  },
  dn_main_field: {
    disciplines_count: '0 dyscyplin | 1 dyscyplina | {count} dyscypliny | {count} dyscyplin',
    evaluated: 'Podlega ewaluacji',
    not_evaluated: 'Nie podlega ewaluacji',
    job_positions_count: '0 etatów | 1 etat | {count} etaty | {count} etatów'
  },
  dn_navigation_menu: {
    academy_reports: 'Raporty uczelni',
    base_blocks: 'Bloki',
    components: 'Komponenty',
    contact: 'Kontakt',
    dashboard: 'Tablica',
    dictionaries: 'Słowniki',
    static_pages: 'Strony statyczne',
    log_in: 'Zaloguj',
    logout: 'Wyloguj z',
    disciplines: 'Dyscypliny',
    discipline_selection: 'Wybór dyscyplin',
    duplicate_reports: 'Raport duplikatów',
    my_statements: 'Moje oświadczenia',
    achievements: 'Osiągnięcia',
    my_achievements: 'Moje osiągnięcia',
    new_achievement: 'Nowe osiągnięcie',
    evidence: 'Ewidencja',
    statements: 'Oświadczenia',
    employees: 'Pracownicy',
    list_of_employees: 'Lista pracowników',
    new_employee: 'Nowy pracownik',
    history: 'Historia',
    instances: 'Instancje',
    faculties: 'Jednostki',
    academies: 'Uczelnie',
    evaluations: 'Ewaluacje',
    my_evaluation: 'Moja ewaluacja',
    import_eksport: 'Import/Eksport',
    import_orcid: 'Import ORCID',
    import_pbn_xml: 'Import PBN XML',
    import_employees: 'Import pracowników',
    import_statements_of_publications: 'Import oświadczeń o publikacjach',
    import_art_achievements: 'Import osiągnięć artystycznych',
    export_pbn_xml: 'Eksport PBN XML',
    integration_pbn: 'Integracja PBN',
    import_pbn_api: 'Import PBN API',
    import_pbn_dictionaries: 'Import słowników z PBN',
    import_polon_dictionaries: 'Import słowników z POL-on',
    import_publications_and_employees: 'Import publikacji i pracowników',
    orcid_reports: 'Raporty exportu ORCID',
    reports: 'Raporty',
    import_polon: 'Import osiągnięć POLon',
    import_polon_employees: 'Import danych pracowników POLon',
    publishers: 'Wydawnictwa',
    periodicals: 'Czasopisma',
    conferences: 'Konferencje',
    kind_of_arts_achievements: 'Rodzaj dorobku artystycznego',
    financing_institutions: 'Instytucje finansujące',
    entities_realizing_projects: 'Podmioty realizujące projekty',
    financing_sources: 'Źródła finansowania',
    rights: 'Uprawnienia',
    users: 'Użytkownicy'
  },
  abk_navigation_menu: {
    dictionaries: 'Słowniki',
    dictionaries_of_consents: 'Słowniki zgód'
  },
  dn_orcid: {
    achievements: 'Zobacz swoje osiągnięcia w orcid',
    change: 'zmień',
    change_orcid: 'Zmiana ORCID ID',
    change_orcid_confirmation: 'Utracisz osiągnięcia zaimportowane do tej pory z ORCID.',
    edit_orcid: 'Edytuj ORCID',
    header: 'Profil',
    link_account: 'Powiąż konto',
    my_header: 'Mój profil',
    no_orcid: 'Nie podano numeru ORCID',
    synchronize: 'synchronizuj'
  },
  dn_polon: {
    add_id: 'Dodaj identyfikatory ministerialne',
    change: 'zmień',
    edit_id: 'Edytuj identyfikatory ministerialne',
    no_id: 'Nie podano',
    no_polon: 'Nie podano żadnych identyfikatorów',
    polon_id: 'Identyfikator POL-on ID',
    polon_uuid: 'Identyfikator POL-on UUID',
    pbn_id: 'Identyfikator PBN ID'
  },
  dn_orcid_integration: {
    archives: 'Archiwum',
    days: '0 dni | 1 dzień | {count} dni',
    information: 'Na tym ekranie możesz zaimportować nowe osiągnięcia z systemu ORCID',
    last_synchronization: 'Ostatnia synchronizacja:',
    monograph_info: 'Autorstwo i redakcje monografii',
    none: 'brak',
    number_of_articles: 'Artykuły:',
    number_of_artistic: 'Artystyczne:',
    number_of_chapters: 'Rozdziały:',
    number_of_monographs: 'Monografie:',
    number_of_monography_and_redactions: 'Monografie z redakcją:',
    number_of_patents: 'Patenty:',
    number_of_projects: 'Projekty:',
    number_of_publications: 'Osiągnięcia:',
    number_of_redactions: 'Redakcje monografii:',
    number_of_services: 'Usługi badawcze:',
    number_of_socials: 'Wpływ na środowisko:',
    see_list: 'zobacz listę',
    synchronized_positions: 'Pobrane osiągnięcia:'
  },
  dn_previous_statement: {
    header: 'Wybrane dyscypliny',
    publications: '0 osiągnięć | 1 osiągnięcie | {count} osiągnięcia | {count} osiągnięć'
  },
  dn_publication_statistics: {
    articles: 'Artykuły',
    artistic: 'Artystyczne',
    chapters: 'Rozdziały',
    header: 'Statystyki osiągnięć',
    monographs: 'Monografie',
    others: 'Pozostałe',
    monography_redactions: 'Monografie z redakcją',
    patents: 'Patenty',
    redactions: 'Redakcje monografii',
    show_all: 'Pokaż wszystkie osiągnięcia',
    summary: 'Podsumowanie',
    tooltip: 'Statystyki wszystkich osiągnięć wraz z sumaryczną wartością punktową.',
    total_publications: 'Wszystkie'
  },
  dn_statements: {
    active: 'Aktywne',
    application: 'Zgłoszenie do liczby N',
    change: 'zmień',
    changed: 'Zmieniono',
    choose: 'wybierz',
    confirm: 'potwierdź',
    choose_discipline: 'Wybierz dyscyplinę',
    choose_disciplines: 'Wybierz dyscypliny',
    deadline: ' | Pozostał 1 miesiąc na zgłoszenie osiągnięcia | Pozostały {count} miesiące na zgłoszenie osiągnięcia | Pozostało {count} miesięcy na zgłoszenie osiągnięcia',
    discipline_selection: 'Wybór dyscyplin',
    edit: 'edytuj',
    edit_n: 'edytuj N',
    edit_statement_end_date: 'Edycja daty zakończenia obowiązywania oświadczenia',
    edit_statement_end_date_confirmation: 'Modyfikacja daty zakończenia danego oświadczenia o dyscyplinie spowoduje zmianę daty w drugim oświadczeniu o dyscyplinie w danym okresie jeśli takie istnieje. Może być również wymagana zmiana daty zakończenia zgłoszenia do N.',
    evaluation: 'Zgłoszenie do ewaluacji',
    fill_statement: 'Brak oświadczenia',
    header: 'Oświadczenia',
    no_discipline: 'Nie wybrano żadnej dyscypliny',
    number_n: 'Zgłoszenie do liczby N',
    number_n_confirmation: 'Chcesz zgłosić daną dyscyplinę do liczby N?',
    number_n_error: 'Okres zgłoszenia do N nie zawiera się w okresie obowiązywania oświadczenia o dyscyplinie',
    number_n_success: 'Liczba N została zadeklarowana pomyślnie',
    remove: 'usuń',
    select_active: 'Lub ustaw aktywne',
    select_a_date: 'Wybierz datę'
  },
  pcg_header_profile_button: {
    change_evaluation_period: 'Zmień aktywny okres ewaluacji',
    changing_evaluation_period: 'Zmiana aktywnego okresu ewaluacji',
    gen_local_token: 'Generuj token',
    local_token: 'Lokalny token',
    lang_change_to: 'Zmień język',
    logout: 'Wyloguj',
    role_change_to: 'Zmień rolę na {role}'
  },
  pcg_pagination_summary: {
    summary: '{from} - {to} z {total}'
  },
  pcg_search_select: {
    add_value: 'Dodaj wartość'
  },
  pcg_options: {
    edit: 'Edytuj',
    preview: 'Podgląd',
    comments: 'Komentarze',
    publication_history: 'Historia osiągnięcia',
    archive: 'Archiwizuj',
    restore: 'Przywróć'
  },
  polon_element: {
    delete: 'Kasuj',
    import: 'Importuj'
  },
  publication_element: {
    achievement_of_great_importance: 'Znaczenie: osiągnięcie o dużym znaczeniu',
    achievement_of_minor_importance: 'Znaczenie: osiągnięcie o mniejszym znaczeniu',
    achievement_other_cases: 'Znaczenie: pozostałe przypadki',
    actions: 'Akcje',
    add_alternative_points: 'Dodaj skorygowane punkty',
    alternative_points: 'Skorygowane punkty',
    archiving: 'Archiwizacja',
    archiving_confirmation: 'Czy na pewno chcesz zarchiwizować osiągnięcie?',
    check_original: 'Wybierz oryginał dla:',
    comments: 'Komentarze',
    comments_error: 'Nie masz uprawnień do edycji komentarzy innych użytkowników',
    conference: 'konferencję',
    conference_search: 'Wpisz nazwę konferencji',
    default_status_pbn: 'Wybierz domyślny status POL-on/PBN',
    discipline: 'Dyscyplina',
    disciplines: 'Dyscypliny',
    duplicate_search: 'Wyszukaj osiągnięcie, które jest duplikatem dla TYTUŁ.',
    edit_alternative_points: 'Edytuj lub usuń skorygowane punkty',
    edit_conference_series: 'Edytuj serię konferencji',
    export_orcid: 'Eksport osiągnięcia do ORCID',
    export_orcid_token: 'Podaj token i ORCID',
    orcid_token: 'Token',
    orcid_user_id: 'ORCID',
    outstanding_achievement: 'Znaczenie: osiągnięcie wybitne',
    periodical: 'czasopismo',
    periodical_publisher: 'wydawnictwo czasopisma',
    periodical_search: 'Wpisz tytuł czasopisma lub jego numer ISSN według Wos lub SCOPUS.',
    points: 'Punkty',
    project_manager: 'Kierownicy',
    publisher: 'wydawnictwo',
    publisher_search: 'Wpisz nazwę wydawnictwa',
    restoring: 'Przywracanie',
    restoring_confirmation: 'Czy na pewno chcesz przywrócić osiągnięcie?',
    show_duplicates: 'Pokaż duplikaty',
    statuses: {
      accepted: 'Zatwierdzono',
      all: 'Wszystkie',
      archive: 'Archiwum',
      draft: 'Szkic',
      duplicate: 'Duplikat',
      for_approval: 'Do akceptacji',
      status: 'Status',
      improve: 'Do poprawy',
      verified: 'Zweryfikowano'
    },
    statusesPolon: {
      all: 'Wszystkie',
      do_not_send: 'Nie wysyłaj',
      found: 'Wysłane/Znaleziono w repozytorium',
      start: 'Wybierz',
      to_send: 'Do wysyłki',
      send_error: 'Błąd wysyłki',
      sent_manually: 'Wysłane manualnie',
      sent_partially: 'Wysłano/Niepełne',
      to_resend: 'Do ponownej wysyłki',
      sent: 'Wysłane',
      status_pbn: 'Status POL-on/PBN'
    },
    statusesPbn: {
      all: 'Wszystkie',
      do_not_send: 'Nie wysyłaj',
      found: 'Wysłane/Znaleziono w repozytorium',
      start: 'Wybierz',
      to_send: 'Do wysyłki',
      send_error: 'Błąd wysyłki',
      sent_manually: 'Wysłane manualnie',
      sent_partially: 'Wysłano/Niepełne',
      to_resend: 'Do ponownej wysyłki',
      sent: 'Wysłane',
      status_pbn: 'Status POL-on/PBN'
    },
    type: 'Typ osiągnięcia',
    wrong_points: 'Nie udało się dodać punktów, niepoprawny format'
  },
  publication_table_element: {
    abstract: 'Streszczenie projektu w języku polskim',
    achievement_source: 'Źródło danych',
    achievement_status: 'Status',
    achievement_status_pbn: 'Status wysyłki POL-on/PBN',
    achievement_title: 'Tytuł osiągnięcia',
    achievement_title_en: 'Tytuł osiągnięcia w innym języku',
    achievement_type: 'Typ',
    achievement_year: 'Rok',
    actions: 'Akcje',
    another_contest_name: 'Inna nazwa konkursu',
    citations: 'Liczba cytowań źródła',
    cite_score: 'CiteScore',
    cited: 'Procent cytowań',
    collective_actions: 'Akcje zbiorcze',
    conference: 'Konferencja',
    contest_name: 'Nazwa konkursu, programu lub przedsięwzięcia',
    contributor_discipline: 'Dyscyplina współautora',
    contributor_disciplines_share: 'Udziały dyscyplin współautora',
    contributor_doctoral_school: 'Kod szkoły doktorskiej współautora',
    contributor_evaluation: 'Zgoda na ewaluację współautora',
    contributor_faculty: 'Przypisane wydziały współautora',
    contributor_name_and_id: 'Dane współautora',
    contributor_part: 'Udział procentowy współautora',
    contributor_points: 'Punkty współautora',
    contributors: 'Współautorzy',
    country_amount: 'Środki krajowe',
    created_at: 'Utworzono',
    discipline: 'Dyscyplina',
    documents: 'Liczba dokumentów',
    doi: 'DOI',
    duplicates: 'Duplikaty w systemie',
    duplicate_id: 'Identyfikator:',
    export_error: 'Błąd wysyłki',
    external_id: 'Zewnętrzny identyfikator',
    external_link: 'Strona internetowa',
    fee_amount: 'Kwota opłaty',
    fee_type: 'Typ opłaty',
    financing_source: 'Podmiot finansujący',
    financing_institution_different: 'Inna instytucja finansująca',
    financing_institution_different_name: 'Nazwa instytucji finansującej',
    flags: 'Flagi',
    foreign_amount: 'Środki zagraniczne',
    foreign_source: 'Podmiot zagraniczny',
    highest_percentile: 'Najwyższy percentyl',
    isbn: 'ISBN',
    issn: 'ISSN',
    ministerial_list: 'Lista ministerialna',
    no_discipline: 'Brak dyscypliny',
    no_doctoral_school_code: 'Brak kodu',
    no_doctoral_school_code_required: 'Nie dotyczy',
    no_evaluation: 'Brak zgody na ewaluację',
    no_faculties: 'Brak przypisanych wydziałów',
    no_points: 'Brak punktów',
    periodical: 'Czasopismo',
    principal_name: 'Podmiot zlecający',
    project_abstract_en: 'Streszczenie projektu w języku angielskim',
    project_acronym: 'Akronim projektu',
    project_end: 'Zakończenie projektu',
    project_individual: 'Projekt realizowany samodzielnie',
    project_leadership: 'Lider',
    project_number: 'Numer projektu',
    project_manager: 'Kierownik',
    project_participants: 'Uczestnicy projektu',
    project_priority_name: 'Nazwa działania lub priorytetu',
    project_start: 'Początek projektu',
    project_types: 'Typy projektu',
    publisher: 'Wydawnictwo',
    related_projects: 'Powiązane projekty',
    report_count: 'Ilość osiągnięć',
    report_name: 'Nazwa',
    sjr: 'SJR',
    snip: 'SNIP',
    source_points: 'Punkty źródła',
    total_amount: 'Środki finansowe ogółem (P)/Wysokość przychodu (UiK)',
    updated_at: 'Zaktualizowano'
  }
}
