<template>
    <div class="achievement"
    :style="publication.duplicates.length > 0 ? 'min-height: 85px' : ''">
        <b-row class="d-flex justify-content-start align-items-center" ref="mainRow">
            <b-col class="col-auto pl-1 pr-1">
                <div class="first-column d-flex justify-content-center align-items-center">
                    <pcg-checkbox  v-if="!duplicate && !orcid && multiOperations"
                                   variant="normal"
                                   class="multi-checkboxes"
                                   v-model="publicationCheckbox"
                                   @input="changeStatus"
                                   :ariaText="$t('general.mark')"></pcg-checkbox>
                    <span class="my-icon" v-if="[7, 30, 32, 35].includes(publication.publicationTypeId)">
                        <i :class="publicationIcon" class="icon-class"></i>
                    </span>
                    <span v-else class="my-icon">
                        <img :src="publicationIcon" class="icon" :alt="$t('components.publication_element.type')">
                    </span>
                </div>
            </b-col>
            <div :style="duplicate ? {} : { 'width': titleWidth + 'px' }" style="margin-left: .8rem">
                <span class="pub-type">
                    <span v-if="publication.conferenceMaterial">{{ $t(publicationType) }},</span>
                    <span v-else>{{ $t(publicationType) }}</span>
                    <span v-if="publication.conferenceMaterial">{{ $t('components.achievement.conference_material') }}</span>
                </span>
                <span class="pub-date">{{ publication.year }}</span>
                <div style="display: inline-block">
                    <span v-if="isDoi">
                        <img src="../assets/images/DOI_logo.png" class="pub-doi" alt="DOI">
                    </span>
                    <span v-if="isDoi" class="pub-doi-number">
                        <a :href="'https://doi.org/' + isDoi" target="_blank">
                            {{ isDoi }}
                        </a>
                    </span>
                </div>
                <div style="display: inline-block; margin-right: .3rem;">
                    <span class="added-by-text">{{ $t('components.achievement.added_by') }}</span>
                    <span class="added-by" v-if="publication.mainSource">
                      <img v-if="publication.mainSource === 'orcid'" src="../assets/images/orcid.png" class="pub-doi" :id="'source_orcid-' + publication.id" alt="ORCiD">
                      <b-tooltip v-if="publication.mainSource === 'orcid'" :target="'source_orcid-' + publication.id">
                          ORCiD
                      </b-tooltip>
                      <img v-if="publication.mainSource === 'pbn'" src="../assets/images/pbn.svg" class="pub-doi" :id="'source_pbn-' + publication.id" alt="PBN ID">
                      <b-tooltip v-if="publication.mainSource === 'pbn'" :target="'source_pbn-' + publication.id">
                          PBN ID
                      </b-tooltip>
                          <img v-if="publication.mainSource === 'polon'" src="../assets/images/new_polon.png" class="pub-doi" :id="'source_polon-' + publication.id" alt="POL-on ID">
                      <b-tooltip v-if="publication.mainSource === 'polon'" :target="'source_polon-' + publication.id">
                          POL-on ID
                      </b-tooltip>
                      <img v-if="publication.mainSource === 'self'" src="../assets/images/sciencecloud-icon.png" class="pub-doi" :id="'source_self-' + publication.id" :alt="$t('views.achievement.list.record')">
                      <b-tooltip v-if="publication.mainSource === 'self'" :target="'source_self-' + publication.id">
                          {{ $t('views.achievement.list.record') }}
                      </b-tooltip>
                      <i v-if="publication.mainSource === 'api'" class="fas fa-cogs fa-lg icon-import" :id="'source_api-' + publication.id" alt="Api"></i>
                      <b-tooltip v-if="publication.mainSource === 'api'" :target="'source_api-' + publication.id">
                          Api
                      </b-tooltip>
                    </span>
                    <span class="added-by-register" v-else>
                      <img src="../assets/images/sciencecloud-icon.png" class="pub-doi" alt="Sciencecloud">
                    </span>
                </div>
                <template v-if="hasRight('view_availability')">
                    <div style="display: inline-block">
                        <span class="added-by-text">{{ $t('components.achievement.availability') }}</span>
                        <span class="repos" v-html="repo()"></span>
                        <b-tooltip :target="'repo_orcid-' + publication.id">
                            ORCiD
                        </b-tooltip>
                        <b-tooltip :target="'repo_pbn-' + publication.id">
                            POL-on ID
                        </b-tooltip>
                        <b-tooltip :target="'repo_self-' + publication.id">
                            {{ $t('views.achievement.list.record') }}
                        </b-tooltip>
                        <b-tooltip :target="'repo_import-' + publication.id">
                            Import
                        </b-tooltip>
                        <b-tooltip :target="'repo_api-' + publication.id">
                            Api
                        </b-tooltip>
                    </div>
                </template>
                <div tabindex="0" :aria-label="$t('components.publication_element.outstanding_achievement')" v-if="publication.artImportance === '0'" :id="'art_importance-' + publication.id" class="main-color" style="display: inline-block">
                  <i aria-hidden="true" class="fas fa-star"></i>
                  <i aria-hidden="true" class="fas fa-star"></i>
                  <i aria-hidden="true" class="fas fa-star"></i>
                  <i aria-hidden="true" class="fas fa-star"></i>
                </div>
                <b-tooltip v-if="publication.artImportance === '0'" :target="'art_importance-' + publication.id">
                    {{ $t('components.publication_element.outstanding_achievement') }}
                </b-tooltip>
                <div tabindex="0" :aria-label="$t('components.publication_element.achievement_of_great_importance')" v-if="publication.artImportance === '3'" :id="'art_importance-' + publication.id" class="main-active-color" style="display: inline-block">
                  <i aria-hidden="true" class="fas fa-star"></i>
                  <i aria-hidden="true" class="fas fa-star"></i>
                  <i aria-hidden="true" class="fas fa-star"></i>
                </div>
                <b-tooltip v-if="publication.artImportance === '3'" :target="'art_importance-' + publication.id">
                    {{ $t('components.publication_element.achievement_of_great_importance') }}
                </b-tooltip>
                <div tabindex="0" :aria-label="$t('components.publication_element.achievement_of_minor_importance')" v-if="publication.artImportance === '6'" :id="'art_importance-' + publication.id" class="main-select-color" style="display: inline-block">
                  <i aria-hidden="true" class="fas fa-star"></i>
                  <i aria-hidden="true" class="fas fa-star"></i>
                </div>
                <b-tooltip v-if="publication.artImportance === '6'" :target="'art_importance-' + publication.id">
                    {{ $t('components.publication_element.achievement_of_minor_importance') }}
                </b-tooltip>
                <div tabindex="0" :aria-label="$t('components.publication_element.achievement_other_cases')" v-if="publication.artImportance === '9'" :id="'art_importance-' + publication.id" class="disabled-color" style="display: inline-block">
                  <i aria-hidden="true" class="fas fa-star"></i>
                </div>
                <b-tooltip v-if="publication.artImportance === '9'" :target="'art_importance-' + publication.id">
                    {{ $t('components.publication_element.achievement_other_cases') }}
                </b-tooltip>
<!--                <span class="small text-primary clicked ml-3" @click="connectPublication" v-if="appKind !== 'BEZ_ANALITYKI'">-->
<!--                    {{ $t('general.duplicate') }}-->
<!--                </span>-->
                <div style="display: inline-block; margin-right: .3rem;">
                  <span class="added-by-text">{{ $t('components.achievement.created_at') }}:</span>
                  <span class="added-by-text">
                      {{ $d(Date.parse(publication.createdAt), 'longDate') }}
                      <i :id="'created-' + publication.id" aria-hidden="true" class="fas fa-info-circle"></i>
                      <b-tooltip :target="'created-' + publication.id" custom-class="long-tooltip">
                        {{ $t('components.achievement.created_at') }}: {{ $d(Date.parse(publication.createdAt), 'long') }}
                        <br/>
                        {{ $t('components.achievement.updated_at') }}: {{ publication.lastModifiedAt ? $d(Date.parse(publication.lastModifiedAt), 'long') : $d(Date.parse(publication.updatedAt), 'long')}}
                      </b-tooltip>
                  </span>
                </div>
                <b-modal ref="connectModal"
                         :hide-header="true"
                         :centered="true"
                         size="lg"
                         v-bind:ok-title="$t('general.confirm')"
                         ok-variant="primary"
                         :no-close-on-esc="true"
                         @ok="connectSave"
                         @cancel="escConnect"
                         v-bind:cancel-title="$t('general.cancel')"
                         cancel-variant="default"
                         v-on-clickaway = "escConnect"
                         content-class="my-modal-content"
                         footer-class="my-modal-footer"
                >
                    <div class="edit-discipline">{{ $t('components.publication_element.check_original') }}</div>
                    <div class="search-text">{{ publication.title }}</div>
                    <live-search
                      :options="pubOptions"
                      v-model="toConnect"
                      v-on:ls-checked="connectChecked"
                      class="connect-modal"
                    />
                </b-modal>
            </div>
            <div v-if="noRegister == 0 && !orcid && !duplicate" class="d-flex justify-content-end" :style="[ statusBottom ? { 'margin-left': '4.7rem', 'margin-top': '.3rem', 'margin-bottom': '.3rem' } : { 'margin-left': '1rem', 'margin-top': 0, 'margin-bottom': 0 } ] ">
                <div ref="statusCol" :class="{ 'inline-status': (initializeStatuses().length === 1 && initializeStatusesPbn().length === 1)}">
                    <div style="display: inline-block" v-if="canEdit() && canDisciplineManagerEdit && initializeStatuses().length === 1" class="wrong-status" :class="{ 'inline-pbn-status': (publication.status === 'accepted' || publication.statusPbn !== 'start') }">
                      <dn-status :status="publication.status" :class-name="publication.status" :contrast="contrast" />
                    </div>
                    <dn-select
                      v-if="canEdit() && canDisciplineManagerEdit && initializeStatuses().length > 1"
                      :options="initializeStatuses()"
                      :default-option="initializeStatuses()[0]"
                      :show-spinner="showStatusSpinner"
                      color="aqua"
                      :contrast="contrast"
                      class="statuses my-dn-select"
                      style="margin-bottom: .3rem"
                      v-model="statusEvent"
                      @input="changePublicationStatus"
                    >
                        <template v-slot:button-text="{ text, className }">
                            <dn-status :status="text" :class-name="className" :contrast="contrast"/>
                        </template>
                        <template v-slot:item-text="{ option }">
                            <dn-status :status="option.text" :class-name="option.class" :contrast="contrast" />
                        </template>
                    </dn-select>
                    <div style="display: inline-block" class="wrong-status" v-if="!canEdit() || !canDisciplineManagerEdit"><dn-status :status="publication.status" :class-name="publication.status" :contrast="contrast"/></div>
                    <div style="display: inline-block" v-if="canEdit() && canDisciplineManagerEdit && initializeStatusesPbn().length === 1 && publication.status === 'accepted' && !hideStatusPbn" class="wrong-status">
                      <dn-status :status="publication.statusPbn" :publicationTypeId="publication.publicationTypeId" :class-name="publication.statusPbn" :pbn="true" :contrast="contrast"/>
                    </div>
                    <dn-select
                        v-else-if="canEdit() && canDisciplineManagerEdit && initializeStatusesPbn().length > 1 && publication.status === 'accepted' && !hideStatusPbn"
                        :options="initializeStatusesPbn()"
                        :default-option="initializeStatusesPbn()[0]"
                        :show-spinner="showStatusSpinner"
                        color="aqua"
                        :contrast="contrast"
                        class="statuses my-dn-select"
                        v-model="statusPbnEvent"
                        @input="changePublicationStatusPbn"
                    >
                      <template v-slot:button-text="{ text, className }">
                        <dn-status :status="text" :class-name="className" :publicationTypeId="publication.publicationTypeId" :pbn="true" :contrast="contrast"/>
                      </template>
                      <template v-slot:item-text="{ option }">
                        <dn-status :status="option.text" :class-name="option.class" :publicationTypeId="publication.publicationTypeId" :pbn="true" :contrast="contrast"/>
                      </template>
                    </dn-select>
                    <div style="display: inline-block" class="wrong-status" v-else-if="(!canEdit() || !canDisciplineManagerEdit) && publication.status === 'accepted' && !hideStatusPbn"><dn-status :status="publication.statusPbn" :class-name="publication.statusPbn" :publicationTypeId="publication.publicationTypeId" :pbn="true" :contrast="contrast"/></div>
                    <div style="display: inline-block" class="wrong-status" v-else-if="publication.statusPbn !== 'start' && !hideStatusPbn"><dn-status :status="publication.statusPbn" :class-name="publication.statusPbn" :publicationTypeId="publication.publicationTypeId" :pbn="true" :contrast="contrast"/></div>
                </div>
                <pcg-options :aria-label="$t('components.publication_element.actions')" class="action-btn" :options="pcgOptions" v-if="pcgOptions.length > 0" :text="$t('components.publication_element.actions')"/>
            </div>
            <b-col v-if="duplicate && !orcid" class="d-flex justify-content-end">
                <div ref="statusCol">
                    <div v-if="canEdit() && canDisciplineManagerEdit && initializeStatuses().length === 0" class="wrong-status">
                        <dn-status :status="publication.status" :class-name="publication.status" :contrast="contrast" />
                    </div>
                    <dn-select
                      v-else-if="canEdit() && canDisciplineManagerEdit"
                      :options="initializeStatuses()"
                      :default-option="initializeStatuses()[0]"
                      :show-spinner="showStatusSpinner"
                      color="aqua"
                      :contrast="contrast"
                      class="statuses my-dn-select"
                      v-model="statusEvent"
                      @input="changePublicationStatus"
                    >
                        <template v-slot:button-text="{ text, className }">
                            <dn-status :status="text" :class-name="className" :contrast="contrast"/>
                        </template>
                        <template v-slot:item-text="{ option }">
                            <dn-status :status="option.text" :class-name="option.class" :contrast="contrast" />
                        </template>
                    </dn-select>
                    <div class="wrong-status" v-else>{{ $t(`components.publication_element.statuses.${publication.status}`) }}</div>
                </div>
                <pcg-options class="action-btn" :options="pcgOptionsDuplicate" v-if="pcgOptionsDuplicate.length > 0" :text="$t('components.publication_element.actions')"/>
            </b-col>
            <b-modal ref="additionalStatus"
                     :hide-header="true"
                     :centered="true"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="additionalStatusSave"
                     @hide="escAdditionalStatus"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     v-on-clickaway = "escAdditionalStatus"
                     content-class="my-modal-content"
                     footer-class="my-modal-footer"
            >
                <div class="confirm-header">{{ $t('components.publication_element.default_status_pbn') }}</div>
                <dn-select
                  v-if="additionalStatuses.length > 0"
                  :options="additionalStatuses"
                  :default-option="{ text: additionalStatuses[0].class, value: additionalStatuses[0].value, class: additionalStatuses[0].class }"
                  color="aqua"
                  class="statuses"
                  v-model="additionalStatus"
                  style="margin-bottom: 3rem; margin-top: 3rem;"
                >
                    <template v-slot:button-text="{ text, className }">
                        <dn-status :status="text" :class-name="className" :pbn="true"/>
                    </template>
                    <template v-slot:item-text="{ option }">
                        <dn-status :status="option.text" :class-name="option.class" :pbn="true" />
                    </template>
                </dn-select>
            </b-modal>
            <pcg-comments-modal
              date-type="long"
              :current-user="currentUser"
              :comments="allComments"
              :title="this.$t('views.publications.comments.modal_title')"
              :btnTitle="this.$t('views.publications.comments.add_comment')"
              :editCommentBtnTitle="this.$t('views.publications.comments.edit_comment_btn')"
              :textareaLabel="this.$t('views.publications.comments.textarea_label')"
              :editCommentPopoverText="this.$t('views.publications.comments.edit_hover')"
              :modal-id="publication.id + '-allCommentsModal'"
              v-on:commentWasAdded="commentAdded"
              :adding-comments-enabled="hasRight('add_comments')"
              :comments-list-enabled="hasRight('edit_comment') || hasRight('edit_own_comment')"
            />
            <pcg-modal
              v-if="hasRight('view_history')"
              size="lg"
              scrollable
              :modalId="publication.id + '-historyModal'"
              :title="$t('views.publications.history.title')"
              icon="pcg-icon-comment"
              btnTitle="OK"
              :action="closeHistory"
            >
              <publication-history v-if="history" :publication="publication" :history="history" :faculties="faculties"/>
              <b-container v-else class="d-flex justify-content-center align-items-center p-2">
                  <dn-loader />
              </b-container>
            </pcg-modal>
            <b-modal ref="archivePublicationConfirm"
                     :hide-header="true"
                     footer-class="my-modal-footer"
                     :centered="true"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="archivePublication"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     content-class="my-modal-content"
            >
                <div class="confirm-header">{{ $t('components.publication_element.archiving') }}</div>
                <div class="confirm-text">{{ $t('components.publication_element.archiving_confirmation') }}</div>
            </b-modal>
            <b-modal ref="restorePublicationConfirm"
                     :hide-header="true"
                     footer-class="my-modal-footer"
                     :centered="true"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="restorePublication"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     content-class="my-modal-content"
            >
                <div class="confirm-header">{{ $t('components.publication_element.restoring') }}</div>
                <div class="confirm-text">{{ $t('components.publication_element.restoring_confirmation') }}</div>
            </b-modal>
            <b-modal ref="exportOrcidModal"
                     :hide-header="true"
                     footer-class="my-modal-footer"
                     :centered="true"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="exportOrcidPublication"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     content-class="my-modal-content"
            >
                <div class="confirm-header" style="margin-bottom: 3rem">{{ $t('components.publication_element.export_orcid') }}</div>
                <div class="confirm-text" style="margin-bottom: 1.5rem">{{ $t('components.publication_element.export_orcid_token') }}</div>
                <div>
                    <label class="my-label">{{ $t('components.publication_element.orcid_token') }}</label>
                </div>
                <pcg-text-input
                  class="my-text-input"
                  style="margin-bottom: 1rem"
                  :showLabel="false"
                  v-model="tokenOrcid"
                />
                <div>
                    <label class="my-label">{{ $t('components.publication_element.orcid_user_id') }}</label>
                </div>
                <pcg-text-input
                  class="my-text-input"
                  style="margin-bottom: 3rem"
                  :showLabel="false"
                  v-model="userOrcid"
                />
            </b-modal>
        </b-row>
        <b-row>
            <b-col class="col-auto pl-1 pr-1">
                <div class="first-column">
<!--                    <span v-if="!listAll && appKind !== 'BEZ_ANALITYKI' && publication.authorType !== 1 && publication.alternativePoints" class="points" :class="pointsClass(publicationPoints(publication))">{{ publication.alternativePoints }} ({{ publication.epoints }})</span>-->
<!--                    <span v-if="!listAll && appKind !== 'BEZ_ANALITYKI' && publication.authorType !== 1 && !publication.alternativePoints" class="points" :class="pointsClass(publicationPoints(publication))">{{ publicationPoints(publication) }}</span>-->
                </div>
            </b-col>
            <b-col class="title">
                <span v-if="publication.monographId">
                    <span v-if="publication.monographTitle">
                        <template>
                            <span>
                                {{ publication.title }}
                            </span>
                        </template>
                    </span>
                </span>
                <span v-else>
                    <template>
                        <span>
                            {{ publication.title }}
                        </span>
                    </template>
                </span>
            </b-col>
        </b-row>
        <b-row v-if="publication.monographId">
            <b-col class="col-auto pl-1 pr-1">
                <div class="first-column"></div>
            </b-col>
            <b-col>
                <span class="monograph-text">{{ $t('components.achievement.monograph') }}:</span>
                <span class="monograph-text" v-if="publication.monographTitle">
                    <template>
                        <span>
                            {{ publication.monographTitle }}
                        </span>
                    </template>
                </span>
            </b-col>
        </b-row>
        <b-row v-if="publication.artDetailedKind">
            <b-col class="col-auto pl-1 pr-1">
                <div class="first-column"></div>
            </b-col>
            <b-col>
                <span class="art-kind">Rodzaj:</span>
                <span class="art-detailed-kind">{{ publication.artDetailedKind }}</span>
            </b-col>
        </b-row>
        <b-row v-if="duplicate">
            <b-col class="col-auto pl-1 pr-1">
                <div class="first-column"></div>
            </b-col>
            <b-col>
                <span class="monograph-text">{{ $t('components.achievement.duplicate') }}:</span>
                <span class="monograph-text">
                    <template>
                        <span>
                            {{ originalPublication.title }}
                        </span>
                    </template>
                </span>
            </b-col>
        </b-row>
        <b-row v-if="publication.publicationTypeId !== 7 && publication.publicationTypeId !== 30
         && publication.publicationTypeId !== 32 && publication.publicationTypeId !== 35 && publication.publicationTypeId !== 45">
            <b-col class="col-auto pl-1 pr-1">
                <div class="first-column"></div>
            </b-col>
            <b-col>
                <div class="d-flex justify-content-start align-items-center" @click="publisherEdit"
                     :class="{'hover-publisher': (publication.authorType !== 1 || !owner), 'orange-color': (publication.publisher.name === null), 'green-color': (publication.publisher.name !== null)}"
                     :style="(canEdit() && canDisciplineManagerEdit) ? { } : { 'cursor': 'text' }">
                    <i class="fas fa-question orange-color small publisher-question" v-if="publication.publisher.name === null">&nbsp;</i>
                    <i class="fas fa-check green-colors small publisher-question" v-else>&nbsp;</i>
                    <div class="discipline-text" v-if="publication.publicationTypeId === 20">{{ $t('views.orcid_publications.periodical') }}:</div>
                    <div class="discipline-text" v-else-if="publication.publicationTypeId === 4">{{ $t('views.orcid_publications.conference') }}:</div>
                    <div class="discipline-text" v-else>{{ $t('views.orcid_publications.publisher') }}:</div>
                    <div class="my-publisher">
                        <span style="margin-right: .5rem">{{ periodical }}</span>
<!--                        <span v-if="publication.conferenceMaterial && appKind !== 'BEZ_ANALITYKI' && publication.publisherPoints > 0" class="points publication-points" :class="pointsClass(publication.publisherPoints)">{{ publication.publisherPoints }}</span>-->
                        <span v-if="!publication.conferenceMaterial && appKind !== 'BEZ_ANALITYKI' && publication.points > 0" class="points publication-points" :class="pointsClass(publication.points)">{{ publication.points }}</span>
                        <span v-if="contrast">
                          <img v-if="!publication.conferenceMaterial && publication.publicationTypeId === 20 && publication.periodicalId !== null && publication.inMenList" src="./../assets/images/mnisw-contrast.png" class="my-mnisw" alt="MEiN"/>
                          <img v-if="publication.publicationTypeId === 4 && publication.conferenceId !== null && publication.inMenList" src="./../assets/images/mnisw-contrast.png" class="my-mnisw" alt="MEiN"/>
                          <img v-if="!publication.conferenceMaterial && publication.publicationTypeId !== 20 && publication.publicationTypeId !== 4  && publication.publisherId !== null && publication.inMenList" src="./../assets/images/mnisw-contrast.png" class="my-mnisw" alt="MEiN"/>
                        </span>
                        <span v-else>
                          <img v-if="!publication.conferenceMaterial && publication.publicationTypeId === 20 && publication.periodicalId !== null && publication.inMenList" src="./../assets/images/mnisw.png" class="my-mnisw" alt="MEiN"/>
                          <img v-if="publication.publicationTypeId === 4 && publication.conferenceId !== null && publication.inMenList" src="./../assets/images/mnisw.png" class="my-mnisw" alt="MEiN"/>
                          <img v-if="!publication.conferenceMaterial && publication.publicationTypeId !== 20 && publication.publicationTypeId !== 4  && publication.publisherId !== null && publication.inMenList" src="./../assets/images/mnisw.png" class="my-mnisw" alt="MEiN"/>
                        </span>
                        <template v-if="publisherSpinner">
                            <span class="text-primary">
                                <spinner v-if="publisherSpinner" />
                            </span>
                        </template>
                        <template v-else>
                            <span class="my-publisher" v-if="canEdit() && canDisciplineManagerEdit">
                                <a href="javascript:;" :aria-label="$t('general.edit')" class="clicked publisher-edit" @click="publisherEdit">
                                    <i class="fas fa-pen" :id="'publisher_edit-' + publication.id"></i>
                                    <b-tooltip v-if="publication.publicationTypeId === 20" :target="'publisher_edit-' + publication.id">
                                        {{ $t('views.orcid_publications.edit_periodical') }}
                                    </b-tooltip>
                                    <b-tooltip v-else-if="publication.publicationTypeId === 4" :target="'publisher_edit-' + publication.id">
                                        {{ $t('views.orcid_publications.edit_conference') }}
                                    </b-tooltip>
                                    <b-tooltip v-else :target="'publisher_edit-' + publication.id">
                                        {{ $t('views.orcid_publications.edit_publisher') }}
                                    </b-tooltip>
                                </a>
                            </span>
                        </template>
                    </div>
                    <b-modal ref="editPublisher"
                             :hide-header="true"
                             :centered="true"
                             size="lg"
                             v-bind:ok-title="$t('general.confirm')"
                             ok-variant="primary"
                             :no-close-on-esc="true"
                             @ok="publisherSave"
                             @hide="escPublisher"
                             v-bind:cancel-title="$t('general.cancel')"
                             cancel-variant="default"
                             v-on-clickaway = "escPublisher"
                             content-class="my-modal-content"
                             footer-class="my-modal-footer"
                    >
                        <div class="edit-discipline">
                            <span>{{ $t('general.edit') }}</span>
                            <span v-if="publication.publicationTypeId === 20">{{ $t('components.publication_element.periodical') }}</span>
                            <span v-else-if="publication.publicationTypeId === 4">{{ $t('components.publication_element.conference') }}</span>
                            <span v-else>{{ $t('components.publication_element.publisher') }}</span>
                        </div>
                        <div v-if="publication.publicationTypeId === 20" class="search-text">{{ $t('components.publication_element.periodical_search') }}</div>
                        <div v-else-if="publication.publicationTypeId === 4" class="search-text">{{ $t('components.publication_element.conference_search') }}</div>
                        <div v-else class="search-text">{{ $t('components.publication_element.publisher_search') }}</div>
                        <live-search
                          class="publisher-search"
                          :options="options"
                          v-model="publisher"
                          v-on:ls-checked="publisherChecked"
                        />
                    </b-modal>
                </div>
            </b-col>
        </b-row>
        <b-row v-if="publication.conferenceMaterial">
            <b-col class="col-auto pl-1 pr-1">
                <div class="first-column"></div>
            </b-col>
            <b-col>
                <div class="d-flex justify-content-start align-items-center" @click="publisherConferenceEdit"
                     :class="{'hover-publisher': (publication.authorType !== 1 || !owner), 'orange-color': (publication.publisherConference.name === null), 'green-color': (publication.publisherConference.name !== null)}"
                     :style="(canEdit() && canDisciplineManagerEdit) ? { } : { 'cursor': 'text' }">
                    <i class="fas fa-question orange-color small publisher-question" v-if="publication.publisherConference.name === null">&nbsp;</i>
                    <i class="fas fa-check green-colors small publisher-question" v-else>&nbsp;</i>
                    <div class="discipline-text">{{ $t('views.orcid_publications.conference') }}:</div>
                    <div class="my-publisher">
                        <span style="margin-right: .5rem">{{ conferenceMaterial }}</span>
                        <span v-if="appKind !== 'BEZ_ANALITYKI' && publication.publisherConferencePoints > 0" class="points publication-points" :class="pointsClass(publication.publisherConferencePoints)">{{ publication.publisherConferencePoints }}</span>
                        <span v-if="contrast">
                          <img v-if="publication.publisherConference.name !== null && publication.conferenceId && publication.inMenList" src="./../assets/images/mnisw-contrast.png" class="my-mnisw" alt="MEiN"/>
                        </span>
                        <span v-else>
                          <img v-if="publication.publisherConference.name !== null && publication.conferenceId && publication.inMenList" src="./../assets/images/mnisw.png" class="my-mnisw" alt="MEiN"/>
                        </span>
                        <template v-if="publisherConferenceSpinner">
                            <span class="text-primary">
                                <spinner v-if="publisherConferenceSpinner" />
                            </span>
                        </template>
                        <template v-else>
                            <span class="my-publisher" v-if="canEdit() && canDisciplineManagerEdit">
                                <a href="javascript:;" :aria-label="$t('general.edit')" class="clicked publisher-edit" @click="publisherConferenceEdit">
                                    <i class="fas fa-pen" :id="'conference_edit-' + publication.id"></i>
                                    <b-tooltip :target="'conference_edit-' + publication.id">
                                        {{ $t('views.orcid_publications.edit_conference') }}
                                    </b-tooltip>
                                </a>
                            </span>
                        </template>
                    </div>
                    <b-modal ref="editConference"
                             :hide-header="true"
                             :centered="true"
                             size="lg"
                             v-bind:ok-title="$t('general.confirm')"
                             ok-variant="primary"
                             :no-close-on-esc="true"
                             @ok="publisherConferenceSave"
                             @hide="escPublisherConference"
                             v-bind:cancel-title="$t('general.cancel')"
                             cancel-variant="default"
                             v-on-clickaway = "escPublisherConference"
                             content-class="my-modal-content"
                             footer-class="my-modal-footer"
                    >
                        <div class="edit-discipline">
                            <span>{{ $t('general.edit') }}</span>
                            <span >{{ $t('components.publication_element.conference') }}</span>
                        </div>
                        <live-search
                          class="publisher-search"
                          :options="conferenceMaterialOptions"
                          v-model="conferenceMaterialPublisher"
                          v-on:ls-checked="publisherConferenceChecked"
                        />
                    </b-modal>
                </div>
            </b-col>
        </b-row>
        <b-row v-if="publication.publicationTypeId !== 30 && publication.publicationTypeId !== 45 && publication.publicationTypeId !== 32" style="margin-top: .3rem" :style="(publication.projectManagers && publication.projectManagers.length > 0) || (publication.projectDisciplines && publication.projectDisciplines.length > 0) || (publication.serviceDisciplines && publication.serviceDisciplines.length > 0) || (publication.socialDisciplines && publication.socialDisciplines.length > 0) ? { 'margin-bottom': '' } : { 'margin-bottom': '1rem' }">
            <b-col class="col-auto pl-1 pr-1">
                <div class="first-column"></div>
            </b-col>
            <b-col>
                <authors :publication="publication" :auto-update="true" style="position: relative; right: .5rem;"
                         :enable-redactors="publication.publicationTypeId === 3 || publication.publicationTypeId === 21"
                         :employee-id="employeeId" :managerDisciplines="managerDisciplines" @disciplineUpdated="disciplineUpdated"
                         :onlyUj="onlyUj"
                />
            </b-col>
        </b-row>
        <b-row v-if="publication.projectManagers && publication.projectManagers.length > 0" style="margin-bottom: .5rem; margin-top: .3rem">
            <b-col class="col-auto pl-1 pr-1">
                <div class="first-column"></div>
            </b-col>
            <b-col>
                <div class="authors fitted d-flex justify-content-start">
                    <div style="min-width: 93px;">
                        <img :src="require('../assets/images/authors.svg')" class="icon circle authors-icon" style="margin-right: .7rem" alt="">
                        <span class="author-text">{{ $t('components.publication_element.project_manager') }}:</span>
                        <div style="display: inline-block" class="pcg-main-color" v-for="manager in publication.projectManagers" :key="manager.id">
                          <span v-if="manager.authorType !== 0 && manager.authorType !== 3" class="dn-gray-color author">
                            <!--  Manager spoza uczelni-->
                            {{ manager.author.name }}
                          </span>
                          <span v-else class="author">
                            <span v-if="currentUser.role !== 'naukowiec' && hasRight('view_employee')">
                              <router-link :to="{ name: 'employee_path', params: {id: manager.author.employeeId } }" >
                                {{ manager.author.name }}
                              </router-link>
                            </span>
                            <span v-else>
                              <router-link v-if="hasRight('view_own_employee')" :to="{ name: 'scientist_dashboard_path' }" >
                                {{ manager.author.name }}
                              </router-link>
                              <span v-else>{{ manager.author.name }}</span>
                            </span>
                          </span>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row v-if="publication.projectDisciplines && publication.projectDisciplines.length > 0" style="margin-bottom: .3rem; margin-top: .3rem">
            <b-col class="col-auto pl-1 pr-1">
                <div class="first-column"></div>
            </b-col>
            <b-col>
                <div class="authors fitted d-flex justify-content-start">
                    <div style="min-width: 93px;">
                        <span class="author-text">{{ $t('components.publication_element.disciplines') }}</span>
                        <div style="display: inline-block" class="dn-gray-color author project-discipline" v-for="discipline in publication.projectDisciplines" :key="discipline.id">
                            <span class="points publication-points" :class="pointsClass(discipline.points)">{{ discipline.points }}</span>
                            <span class="project-discipline-name" style="margin-right: .3rem">{{ projectDisciplineName(discipline.disciplineId) }}</span>
                            <span class="project-discipline-name">{{ discipline.share }}%</span>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row v-if="publication.serviceDisciplines && publication.serviceDisciplines.length > 0" style="margin-bottom: .3rem; margin-top: .3rem">
            <b-col class="col-auto pl-1 pr-1">
                <div class="first-column"></div>
            </b-col>
            <b-col>
                <div class="authors fitted d-flex justify-content-start">
                    <div style="min-width: 93px;">
                        <span class="author-text">{{ $t('components.publication_element.disciplines') }}</span>
                        <div style="display: inline-block" class="dn-gray-color author project-discipline" v-for="discipline in publication.serviceDisciplines" :key="discipline.id">
                            <span class="points publication-points" :class="pointsClass(discipline.points)">{{ discipline.points }}</span>
                            <span class="project-discipline-name" style="margin-right: .3rem">{{ projectDisciplineName(discipline.disciplineId) }}</span>
                            <span class="project-discipline-name">{{ discipline.share }}%</span>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row v-if="publication.socialDisciplines && publication.socialDisciplines.length > 0" style="margin-bottom: .3rem; margin-top: .3rem">
            <b-col class="col-auto pl-1 pr-1">
                <div class="first-column"></div>
            </b-col>
            <b-col>
                <div class="authors fitted d-flex justify-content-start">
                    <div style="min-width: 93px;">
                        <span class="author-text">{{ $t('components.publication_element.discipline') }}</span>
                        <div style="display: inline-block" class="dn-gray-color author project-discipline" v-for="(discipline, index) in publication.socialDisciplines" :key="discipline.id">
                            <span :id="'social-points-' + publication.id + '-' + index" class="points publication-points" :class="pointsClass(discipline.points)">{{ discipline.points }}</span>
                            <b-tooltip :target="'social-points-' + publication.id + '-' + index">
                                {{ $t('views.achievement.new.pub.social_points_tooltip') }}
                            </b-tooltip>
                            <span class="project-discipline-name">{{ projectDisciplineName(discipline.disciplineId) }}</span>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row v-if="hasRight('view_publication_flags') && (publication.flags.length > 0)">
          <b-col class="col-auto pl-1 pr-1">
            <div class="first-column"></div>
          </b-col>
          <b-col>
            <div v-for="flag in flagNames" :key="'flag-' + flag.id" class="tags-container">
              <span class="tag">
                {{ flag }}
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="hasRight('view_attachments') || hasRight('view_links')" class="justify-end">
          <b-col class="col-auto pl-1 pr-1">
            <div class="first-column"></div>
          </b-col>
          <b-col>
            <div class="section text-right">
              <div class="attachments" v-for="item in publication.publicationAttachments" :key="'attachment-' + item.id"
                   v-if="hasRight('view_attachments') && publication.publicationAttachments && publication.publicationAttachments.length > 0">
                <button v-if="item.evidenceFile" class="download-btn" @click="downloadFromFile(item.id)" :id="'attachment-' + item.id">
                  <i class="pcg-icon-clip pcg-sm mr-2"></i>
                </button>
                <b-tooltip :target="'attachment-' + item.id">
                  {{ item.fileDescription ? item.fileDescription : item.fileName }}
                </b-tooltip>
              </div>
              <div  class="attachments" v-if="hasRight('view_links')" v-for="link in publication.publicationLinks" :key="'link-' + link.id">
                <a v-if="link.linkDescription || link.link" :href="link.link" target="_blank" :id="'link-' + link.id" class="download-btn">
                  <i class="fa fa-external-link-alt pcg-sm"></i>
                  <span v-if="link.linkDescription">
                    {{
                      link.linkDescription.length > 14 ? `${link.linkDescription.substring(0,14)}...` : link.linkDescription
                    }}
                  </span>
                  <span v-else>
                    {{ link.link.length > 14 ? `${link.link.substring(0,14)}...` : link.link }}
                  </span>
                </a>
                <b-tooltip :target="'link-' + link.id">
                  {{ link.linkDescription ? link.linkDescription : link.link }}
                </b-tooltip>
              </div>
            </div>
          </b-col>
        </b-row>
      <template v-if="publication.duplicates.length > 0">
            <b-button size="sm" class="duplicates-control" v-b-toggle="'duplicate-collapse-' + publication.id" @click="showDuplicates = !showDuplicates">
                <div :id="'duplicate-' + publication.id">
                    <span class="duplicate-counter">{{ publication.duplicates.length }}</span>
                    <i v-if="showDuplicates" class="fas fa-angle-double-up my-arrow"></i>
                    <i v-else class="fas fa-angle-double-down my-arrow"></i>
                </div>
            </b-button>
            <b-tooltip :target="'duplicate-' + publication.id">
                {{ $t('components.publication_element.show_duplicates') }}
            </b-tooltip>
            <b-collapse :id="'duplicate-collapse-' + publication.id" class="duplicate-container">
                <div v-for="p in publication.duplicates" :key="p.id" class="achievement duplicates">
                    <publication-element :publication="p" :duplicate="true" :list-all="true" :orcid="orcid" :originalPublication="publication"
                                         @changePublicationStatus="$emit('changePublicationStatus')" :managerDisciplines="managerDisciplines" :flags="flags" />
                </div>
            </b-collapse>
        </template>
    </div>
</template>

<script>
import translateDictionary from '../mixins/translate_dictionary'
import waitForJobs from '../mixins/wait_for_jobs'
import pointsClasses from '../mixins/points_classes'
import unexpectedError from '../mixins/unexpected_error'
import { mixin as clickaway } from 'vue-clickaway'
import { mapGetters } from 'vuex'
import ApiPublications from '../api/publications'
import PublicationHistory from './PublicationHistory'
import LiveSearch from './LiveSearch'
import Spinner from './Spinner'
import ApiPublicationAttachments from '../api/publication_attachments'
import ApiComments from '../api/comments'
import DnSelect from './DnSelect'
import DnStatus from './DnStatus'
import hasRight from '../mixins/has_right'
import DnLoader from './DnLoader'
import Authors from './Authors'
import windowResize from '../mixins/window_resize'

export default {
  name: 'PublicationElement',
  components: {
    DnStatus,
    DnSelect,
    // DnFileInput,
    PublicationHistory,
    LiveSearch,
    Spinner,
    Authors,
    DnLoader
  },
  mixins: [clickaway, hasRight, pointsClasses, translateDictionary, unexpectedError, waitForJobs, windowResize],
  data: function () {
    return {
      appKind: '',
      noRegister: 1,
      noRepo: 1,
      imgAuthors: require('../assets/images/authors.svg'),
      editAuthorsStyle: '',
      authors: [],
      redactors: [],
      publisher: {},
      conferenceMaterialPublisher: {},
      toConnect: {},
      jobId: null,
      discipline_id: 0,
      editedAuthor: null,
      editedRedactor: null,
      authorSpinner: false,
      redactorSpinner: false,
      publisherSpinner: false,
      publisherConferenceSpinner: false,
      roles: [],
      showAddAttachment: false,
      myPublicationDisciplines: [],
      myAuthorDisciplines: [],
      myRedactorDisciplines: [],
      myDoctorDisciplines: [],
      showPublicationAll: false,
      showAuthorAll: false,
      showRedactorAll: false,
      fromPublicationStatements: false,
      fromAuthorStatements: [],
      fromRedactorStatements: [],
      editedAuthorCorrect: false,
      authorCopy: 0,
      editedRedactorCorrect: false,
      redactorCopy: 0,
      changingDiscipline: false,
      copiedDisciplines: [],
      errors: [],
      alternativePoints: null,
      changingPointsAuthor: null,
      authorsHover: [],
      redactorsHover: [],
      pointsErrors: [],
      pcgOptions: [
        // {
        //   text: 'Duplikat',
        //   action: () => { this.connectPublication() }
        // }
      ],
      pcgOptionsDuplicate: [],
      allComments: [
      ],
      history: null,
      publicationCheckbox: false,
      originalStatus: null,
      showDuplicates: false,
      showStatusSpinner: false,
      statusEvent: null,
      statusPbnEvent: null,
      titleWidth: 0,
      statusBottom: false,
      hideStatusPbn: false,
      additionalStatus: null,
      additionalStatuses: [
        { text: 'do_not_send', value: 'not_sending_pbn', class: 'do_not_send' },
        { text: 'to_send', value: 'approved_pbn', class: 'to_send' },
        { text: 'sent_manually', value: 'sending_manually_pbn', class: 'sent_manually' }
      ],
      tokenOrcid: null,
      userOrcid: null,
      currentUser: null
    }
  },
  props: {
    duplicate: {
      type: Boolean,
      default: false
    },
    originalPublication: Object,
    employeeId: String,
    flags: Array,
    publication: Object,
    owner: {
      type: Boolean,
      default: false
    },
    listAll: {
      type: Boolean,
      default: false
    },
    multiCheckbox: {
      type: Boolean,
      default: false
    },
    orcid: {
      type: Boolean,
      default: false
    },
    contrast: {
      type: Boolean,
      default: true
    },
    multiSelect: String,
    unselectedPublications: Array,
    selectedPublications: Array,
    downloadAttachmentAction: {
      type: Function,
      default: ApiPublicationAttachments.getPublicationAttachment
    },
    multiOperations: {
      type: Boolean,
      default: false
    },
    managerDisciplines: Array,
    faculties: Array,
    onlyUj: String
  },
  beforeMount () {
    if (this.$store.getters['publicationTypes/publicationTypes'].length === 0) {
      this.$store.dispatch('publicationTypes/setPublicationTypes', null)
    }
    if (this.$store.getters['disciplines/disciplines'].length === 0) {
      this.$store.dispatch('disciplines/setDisciplines', null)
    }
    this.roles = this.$store.getters['auth/roles']
    this.currentUser = {
      id: this.$store.getters['auth/id'],
      author: this.$store.getters['auth/fullName'],
      entity: '',
      avatar: this.$store.getters['auth/avatar'],
      role: this.$store.getters['auth/currentRole']
    }
    this.appKind = process.env.APP_KIND
    this.noRegister = process.env.NO_REGISTER
    this.noRepo = process.env.NO_REPO

    this.buildActions()
  },
  mounted () {
    this.discipline_id = this.publication.disciplineId || 0
    this.originalStatus = this.publication.status
    this.initializeDisciplines()
    this.checkAuthorStatements()
    this.checkRedactorStatements()
    this.initializePublicationDisciplines()
    this.initializeRedactorDisciplines()
    this.initializeDoctorDisciplines()
    this.checkPublicationStatements()
    this.setStatus()
    this.publication.authors.forEach(author => {
      this.authorsHover.push(false)
    })
    this.publication.redactors.forEach(redactor => {
      this.redactorsHover.push(false)
    })
    this.calculateTitleWidth()
  },
  computed: {
    ...mapGetters('publicationTypes', ['publicationTypes']),
    ...mapGetters('disciplines', ['disciplines']),
    ...mapGetters('auth', ['fullName']),
    difference () {
      return _.cloneDeep(this.allComments)
    },
    addedBy () {
      if (this.publication && this.publication.sources && this.publication.sources.length) {
        for (let i = 0; i < this.publication.sources.length; i++) {
          if (process.env.APP_KIND === 'BEZ_ANALITYKI') {
            if (this.publication.sources[i].sourceName === 'orcid') {
              return this.publication.sources[i].sourceName
            }
          } else {
            if (this.publication.sources[i].sourceName === 'pcg' || this.publication.sources[i].sourceName === 'orcid' || this.publication.sources[i].sourceName === 'pbn') {
              return this.publication.sources[i].sourceName
            }
          }
        }
      }
      return null
    },
    disciplineName () {
      if (this.publication.disciplineId === null) {
        return ''
      }
      const idx = this.copiedDisciplines.findIndex(b => b.id === this.publication.disciplineId)
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.copiedDisciplines[idx].text
        } else {
          return this.copiedDisciplines[idx].text_en
        }
      } else {
        return ''
      }
    },
    disciplineId: {
      get () {
        return this.discipline_id
      },
      set (newValue) {
        this.discipline_id = parseInt(newValue)
      }
    },
    editedAuthorDisciplineId: {
      get () {
        if (this.editedAuthor.disciplineId === undefined || this.editedAuthor.disciplineId == null) {
          return null
        } else {
          return this.editedAuthor.disciplineId
        }
      },
      set (newValue) {
        this.editedAuthor.disciplineId = parseInt(newValue)
      }
    },
    editedRedactorDisciplineId: {
      get () {
        if (this.editedRedactor.disciplineId === undefined || this.editedRedactor.disciplineId == null) {
          return null
        } else {
          return this.editedRedactor.disciplineId
        }
      },
      set (newValue) {
        this.editedRedactor.disciplineId = parseInt(newValue)
      }
    },
    flagNames () {
      const result = []
      this.publication.flags.forEach((flagId) => {
        const found = this.flags.find(element => element.id === flagId)
        if (found) {
          result.push(found.text)
        }
      })
      return result
    },
    isDoi () {
      if (this.publication && this.publication.sources && this.publication.sources.length) {
        for (let i = 0; i < this.publication.sources.length; i++) {
          if (this.publication.sources[i].sourceName === 'doi') {
            return this.publication.sources[i].sourceId
          }
        }
      }
      return null
    },
    publicationType () {
      const pubId = this.publication.publicationTypeId
      let result = ''
      switch (pubId) {
        case 3:
          result = 'monograph'
          break
        case 4:
        case 25:
          result = 'conference'
          break
        case 7:
          result = 'patent'
          break
        case 19:
          result = 'chapter'
          break
        case 20:
          result = 'article'
          break
        case 21:
          result = 'monograph_redaction'
          break
        case 30:
          result = 'service'
          break
        case 32:
          result = 'project'
          break
        case 35:
          result = 'artistic_activity'
          break
        case 45:
          result = 'environmental_impact'
          break
        default:
          result = 'other'
      }
      if (result === 'monograph' && this.publication.redactors && this.publication.redactors.length > 0) {
        result = 'monograph_and_redaction'
      }
      if (result === 'service' && this.publication.isCommercialization) {
        result = 'commercialization'
      }
      return 'views.publications.types.' + result
    },
    periodical () {
      let result = ''
      if (this.publication.publisher.name) {
        result = this.publication.publisher.name
        if (this.publication.publisher.issn !== undefined && this.publication.publisher.issn !== null) {
          result += ' | ISSN: ' + this.publication.publisher.issn
        }
      } else {
        if (this.publication.publisherTitle) {
          result = this.publication.publisherTitle || ''
          result += this.sources()
        }
      }
      return result
    },
    conferenceMaterial () {
      let result = ''
      result = this.publication.publisherConference.name ? this.publication.publisherConference.name : ''
      return result
    },
    publicationIcon () {
      let icon = ''
      switch (this.publication.publicationTypeId) {
        case 3:
        case 21:
          icon = require('../assets/images/monograph.svg')
          break
        case 7:
          icon = 'fas fa-cog'
          break
        case 19:
          icon = require('../assets/images/article.svg')
          break
        case 20:
          icon = require('../assets/images/chapter.svg')
          break
        case 30:
          icon = 'pcg-icon-globe'
          break
        case 32:
          icon = 'fas fa-project-diagram'
          break
        case 35:
          icon = 'fa fa-film'
          break
        default:
          icon = require('../assets/images/chapter.svg')
      }
      return icon
    },
    options () {
      const result = {
        publicationId: this.publication.id,
        publicationTypeId: this.publication.publicationTypeId,
        year: this.publication.year
      }
      this.publication.sources.forEach(source => {
        if (source.sourceName === 'issn') {
          result.issn = source.sourceId
        }
        if (source.sourceName === 'isbn') {
          result.isbn = source.sourceId
        }
      })
      return {
        path: 'publishers_search',
        params: result
      }
    },
    conferenceMaterialOptions () {
      const result = {
        publicationId: this.publication.id,
        publicationTypeId: this.publication.publicationTypeId,
        year: this.publication.year
      }
      if (this.publication.conferenceMaterial) {
        result.conference = this.publication.conferenceMaterial
      }
      return {
        path: 'publishers_conference_material',
        params: result
      }
    },
    pubOptions () {
      const result = {
        publication_id: this.publication.id
      }
      return {
        path: 'connect_publications',
        params: result
      }
    },
    canDisciplineManagerEdit () {
      let edit = false
      if (this.currentUser.role !== 'kierownikdyscypliny') {
        return true
      } else {
        this.publication.authors.forEach(author => {
          if (this.managerDisciplines.includes(author.disciplineId)) {
            edit = true
          }
        })
        this.publication.redactors.forEach(redactor => {
          if (this.managerDisciplines.includes(redactor.disciplineId)) {
            edit = true
          }
        })
        return edit
      }
    }
  },
  methods: {
    buildActions () {
      this.pcgOptions = []
      // eslint-disable-next-line eqeqeq
      if (this.noRegister == 0) {
        if (this.publication.status === 'draft') {
          if (((this.currentUser.role !== 'kierownikdyscypliny') || (this.currentUser.role === 'kierownikdyscypliny' && this.canDisciplineManagerEdit)) && (this.hasRight('edit_achievement') || (this.owner && this.hasRight('edit_own_achievement')))) {
            this.pcgOptions.push({
              text: this.$t('components.pcg_options.edit'),
              href: `/achievement/${this.publication.id}/edit`
            })
            this.pcgOptionsDuplicate.push({
              text: this.$t('components.pcg_options.edit'),
              href: `/achievement/${this.publication.id}/edit`
            })
          }
        } else if (this.publication.status === 'improve') {
          if (((this.currentUser.role !== 'kierownikdyscypliny') || (this.currentUser.role === 'kierownikdyscypliny' && this.canDisciplineManagerEdit)) && ((this.hasRight('edit_achievement') || (this.owner && this.hasRight('edit_own_achievement'))) && (this.hasRight('to_improve_edit') || this.hasRight('out_of_draft_editing')))) {
            this.pcgOptions.push({
              text: this.$t('components.pcg_options.edit'),
              href: `/achievement/${this.publication.id}/edit`
            })
            this.pcgOptionsDuplicate.push({
              text: this.$t('components.pcg_options.edit'),
              href: `/achievement/${this.publication.id}/edit`
            })
          }
        } else {
          if (((this.currentUser.role !== 'kierownikdyscypliny') || (this.currentUser.role === 'kierownikdyscypliny' && this.canDisciplineManagerEdit)) && ((this.hasRight('edit_achievement') || (this.owner && this.hasRight('edit_own_achievement'))) && this.hasRight('out_of_draft_editing'))) {
            this.pcgOptions.push({
              text: this.$t('components.pcg_options.edit'),
              href: `/achievement/${this.publication.id}/edit`
            })
            this.pcgOptionsDuplicate.push({
              text: this.$t('components.pcg_options.edit'),
              href: `/achievement/${this.publication.id}/edit`
            })
          }
        }
        if (this.hasRight('view_achievement') || (this.owner && this.hasRight('view_own_achievement'))) {
          this.pcgOptions.push({
            text: this.$t('components.pcg_options.preview'),
            href: `/achievement/${this.publication.id}/show`
          })
          this.pcgOptionsDuplicate.push({
            text: this.$t('components.pcg_options.preview'),
            href: `/achievement/${this.publication.id}/show`
          })
        }
        if (this.hasRight('view_comments')) {
          this.pcgOptions.push({
            text: this.$t('components.pcg_options.comments'),
            action: () => { this.showComments() }
          })
          this.pcgOptionsDuplicate.push({
            text: this.$t('components.pcg_options.comments'),
            action: () => { this.showComments() }
          })
        }
        if (this.hasRight('view_history')) {
          this.pcgOptions.push({
            text: this.$t('components.pcg_options.publication_history'),
            action: () => {
              this.getHistory()
            }
          })
          this.pcgOptionsDuplicate.push({
            text: this.$t('components.pcg_options.publication_history'),
            action: () => {
              this.getHistory()
            }
          })
        }
        if (((this.currentUser.role !== 'kierownikdyscypliny') || (this.currentUser.role === 'kierownikdyscypliny' && this.canDisciplineManagerEdit)) && (this.hasRight('event_to_archive') && this.publication.status !== 'archive')) {
          this.pcgOptions.push({
            text: this.$t('components.pcg_options.archive'),
            action: () => { this.archivePublicationsModal() }
          })
          this.pcgOptionsDuplicate.push({
            text: this.$t('components.pcg_options.archive'),
            action: () => { this.archivePublicationsModal() }
          })
        }
        if (((this.currentUser.role !== 'kierownikdyscypliny') || (this.currentUser.role === 'kierownikdyscypliny' && this.canDisciplineManagerEdit)) && (this.hasRight('event_unarchive') && this.publication.status === 'archive')) {
          this.pcgOptions.push({
            text: this.$t('components.pcg_options.restore'),
            action: () => { this.restorePublicationsModal() }
          })
          this.pcgOptionsDuplicate.push({
            text: this.$t('components.pcg_options.restore'),
            action: () => { this.restorePublicationsModal() }
          })
        }
        if (this.hasRight('export_orcid')) {
          this.pcgOptions.push({
            text: 'Eksportuj do ORCID',
            action: () => { this.exportOrcidModal() }
          })
        }
        if (((this.currentUser.role !== 'kierownikdyscypliny') || (this.currentUser.role === 'kierownikdyscypliny' && this.canDisciplineManagerEdit)) && (this.hasRight('edit_achievement') || (this.owner && this.hasRight('edit_own_achievement')))) {
          this.pcgOptionsDuplicate.push({
            text: this.$t('views.achievement.mark_as_original'),
            action: () => { this.setAsOriginal() }
          })
        }
      }
    },
    setAsOriginal () {
      ApiPublications.changeStatus(this.publication.id, 'unduplicate', null, 'duplicate_original')
        .then(result => {
          this.showStatusSpinner = true
          setTimeout(() => {
            this.$emit('changePublicationStatus')
          }, 1000)
          setTimeout(() => {
            this.showStatusSpinner = false
            this.hideStatusPbn = false
          }, 3000)
        })
        .catch(error => {
          console.log(error)
          this.showStatusSpinner = false
          this.hideStatusPbn = false
          this.unexpectedError()
        })
    },
    projectDisciplineName (disciplineId) {
      if (disciplineId === null) {
        return ''
      }
      const idx = this.disciplines.findIndex(b => b.id === disciplineId.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.disciplines[idx].text
        } else {
          return this.disciplines[idx].text_en
        }
      } else {
        return ''
      }
    },
    disciplineUpdated () {
      this.$emit('refreshPublications')
      setTimeout(() => {
        this.calculateTitleWidth()
      }, 1000)
      setTimeout(() => {
        this.calculateTitleWidth()
      }, 2000)
    },
    calculateTitleWidth () {
      if (this.orcid) {
        if (this.$refs.mainRow) {
          this.titleWidth = this.$refs.mainRow.clientWidth - 80
        }
      } else {
        if (this.$refs.mainRow) {
          if (this.publication.status === 'accepted' && this.$refs.mainRow.clientWidth < 800) {
            this.titleWidth = this.$refs.mainRow.clientWidth - 80
            this.statusBottom = true
          } else if (this.publication.status !== 'accepted' && this.$refs.mainRow.clientWidth < 700) {
            this.titleWidth = this.$refs.mainRow.clientWidth - 80
            this.statusBottom = true
          } else {
            if (this.$refs.statusCol) {
              // 130px ze względu na pierwszą kolumnę, pcg-options i paddingi
              this.titleWidth = this.$refs.mainRow.clientWidth - (this.$refs.statusCol.clientWidth + 130)
              this.statusBottom = false
            }
          }
        }
      }
    },
    canEdit () {
      return this.hasRight('edit_achievement') || (this.owner && this.hasRight('edit_own_achievement'))
    },
    downloadFromFile (id) {
      const routeData = this.$router.resolve({ path: `/api/v1/publication_attachments/${id}/download_attachment` })
      window.open(routeData.href, '_blank')
    },
    authorDisciplineName (author) {
      if (author.disciplineId === null) {
        return ''
      }
      const idx = this.copiedDisciplines.findIndex(b => b.id === author.disciplineId)
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.copiedDisciplines[idx].text
        } else {
          return this.copiedDisciplines[idx].text_en
        }
      } else {
        return ''
      }
    },
    isSource (provider) {
      let result = false
      if (this.publication.sources) {
        for (const source of this.publication.sources) {
          if (source.sourceName === provider) {
            result = true
          }
        }
      }
      return result
    },
    repo () {
      let src = ''
      let img = ''
      const style = "' style='width: 20px; margin-right: 5px; position: relative; bottom: .1rem;"
      const styleIcon = "' style='margin-right: 5px; position: relative; top: .05rem; color: #253B80;"
      let result = ''
      let repo = ''
      repo = 'repo_orcid-' + this.publication.id
      src = require('../assets/images/orcid.png')
      img = '<img id=' + repo + " src='" + src + style
      if (this.isSource('orcid')) {
        img += "'>"
      } else {
        img += "opacity: 0.8; filter: grayscale(100%);'>"
      }
      result += img
      if (process.env.APP_KIND !== 'BEZ_ANALITYKI') {
        repo = 'repo_pbn-' + this.publication.id
        src = require('../assets/images/pbn.svg')
        img = '<img id=' + repo + " src='" + src + style
        if (this.isSource('pbn') || this.isSource('polon')) {
          img += "'>"
        } else {
          img += "opacity: 0.8; filter: grayscale(100%);'>"
        }
        result += img
      }
      repo = 'repo_self-' + this.publication.id
      src = require('../assets/images/sciencecloud-icon.png')
      img = '<img id=' + repo + " src='" + src + style
      if (this.isSource('self')) {
        img += "'>"
      } else {
        img += "opacity: 0.8; filter: grayscale(100%);'>"
      }
      result += img
      repo = 'repo_api-' + this.publication.id
      img = '<i id=' + repo + " class='fas fa-cogs fa-lg'" + src + styleIcon
      if (this.isSource('api')) {
        img += "'></i>"
      } else {
        img += "opacity: 0.8; filter: grayscale(100%);'></i>"
      }
      result += img
      repo = 'repo_import-' + this.publication.id
      img = '<i id=' + repo + " class='fas fa-file-import fa-lg'" + styleIcon
      img += "opacity: 0.3; filter: grayscale(100%);'></i>"
      result += img
      return result
    },
    sources () {
      let result = ''
      this.publication.sources.forEach(source => {
        if (source.sourceName === 'issn') {
          result += ' | ISSN: ' + source.sourceId
        }
        // if (source.sourceName === 'isbn') {
        //   result += ' | ISBN: ' + source.sourceId
        // }
      })
      return result
    },
    publisherEdit () {
      if ((this.publication.authorType === 1 && this.owner) || !this.canEdit() || !this.canDisciplineManagerEdit) {
        return
      }
      if (this.publication.publisher.id !== null) {
        this.publisher = { id: this.publication.publisher.id, value: this.publication.publisher.name }
      } else {
        this.publisher = { id: '', value: this.publication.publisherTitle }
      }
      this.$refs.editPublisher.show()
    },
    escPublisher () {
      this.$refs.editPublisher.hide()
    },
    publisherSave () {
      this.publisherSpinner = true
      const conferenceMaterial = false
      ApiPublications.update_publisher(this.publication.id, this.publisher, conferenceMaterial)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.publisherSpinner = true
            this.waitForJob(jobId, this.publisherUpdate, this.publisherUpdate)
          }
        })
    },
    publisherChecked () {
      this.$refs.editPublisher.hide()
      this.publisherSave()
    },
    publisherUpdate () {
      this.publicationUpdate()
      this.publisherSpinner = false
    },
    publisherConferenceEdit () {
      if (this.publication.authorType === 1 && this.owner) {
        return
      }
      if (this.publication.publisherConference.id !== null) {
        this.conferenceMaterialPublisher = { id: this.publication.publisherConference.id, value: this.publication.publisherConference.name }
      } else {
        this.conferenceMaterialPublisher = { id: '', value: null }
      }
      this.$refs.editConference.show()
    },
    escPublisherConference () {
      this.$refs.editConference.hide()
    },
    publisherConferenceSave () {
      const conferenceMaterial = true
      ApiPublications.update_publisher(this.publication.id, this.conferenceMaterialPublisher, conferenceMaterial)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.publisherConferenceSpinner = true
            this.waitForJob(jobId, this.publisherConferenceUpdate, this.publisherConferenceUpdate)
          }
        })
      this.conferenceMaterialPublisher = null
    },
    publisherConferenceChecked () {
      this.publisherConferenceSpinner = true
      this.$refs.editConference.hide()
      this.publisherConferenceSave()
    },
    publisherConferenceUpdate () {
      this.publicationUpdate()
      this.publisherConferenceSpinner = false
    },
    authorsEdit (publication) {
      this.authors = this.copyAuthors(publication.authors)
      this.$refs.authors.show()
    },
    redactorsEdit (publication) {
      this.redactors = this.copyAuthors(publication.redactors)
      this.$refs.redactors.show()
    },
    copyAuthors (authors) {
      const newAuthors = []
      authors.forEach(a => {
        const na = {}
        for (const key in a) {
          na[key] = a[key]
        }
        newAuthors.push(na)
      })
      return newAuthors
    },
    escAuthors () {
      this.$refs.authors.hide()
      this.errors = []
    },
    escRedactors () {
      this.$refs.redactors.hide()
      this.errors = []
    },
    authorsUpdate () {
      this.editedAuthor = null
      this.publicationUpdate()
      this.authorSpinner = false
      this.changingDiscipline = false
    },
    redactorsUpdate () {
      this.editedRedactor = null
      this.publicationUpdate()
      this.redactorSpinner = false
      this.changingDiscipline = false
    },
    validateAuthors (authors) {
      // TODO
      return true
    },
    authorDisciplineEdit (author) {
      this.authorCopy = author.disciplineId
      this.editedAuthor = author
      this.changeAuthorShowAll()
      this.checkEditedAuthorStatements()
      this.$refs.discipline.show()
    },
    escAuthorDiscipline () {
      if (!this.changingDiscipline) {
        for (let i = 0; i < this.publication.authors.length; i++) {
          if (this.publication.authors[i].employeeId === this.editedAuthor.employeeId) {
            this.publication.authors[i].disciplineId = this.authorCopy
          }
        }
      }
      this.editedAuthor = null
      this.$refs.discipline.hide()
    },
    redactorDisciplineEdit (author) {
      this.redactorCopy = author.disciplineId
      this.editedRedactor = author
      this.changeRedactorShowAll()
      this.checkEditedRedactorStatements()
      this.$refs.disciplineRedactor.show()
    },
    escRedactorDiscipline () {
      if (!this.changingDiscipline) {
        for (let i = 0; i < this.publication.redactors.length; i++) {
          if (this.publication.redactors[i].employeeId === this.editedRedactor.employeeId) {
            this.publication.redactors[i].disciplineId = this.redactorCopy
          }
        }
      }
      this.editedRedactor = null
      this.$refs.disciplineRedactor.hide()
    },
    redactorDisciplineSave () {
      this.changingDiscipline = true
      this.originalRedactors = this.publication.redactors
      this.$refs.disciplineRedactor.hide()
      ApiPublications.update_authors(this.publication.id, this.publication.redactors, this.originalRedactors)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.redactorSpinner = true
            this.waitForJob(jobId, this.redactorsUpdate, this.redactorsUpdate)
          }
        })
    },
    publicationUpdate () {
      ApiPublications.getPublication(this.publication.id, this.employeeId)
        .then(result => {
          const np = result.data
          for (const prop in np) {
            this.publication[prop] = np[prop]
          }
        })
    },
    changeSubmitted (publication, author = null) {
      if (author === null) {
        return ApiPublications.changeSubmitted(publication, author, this.employeeId)
      } else {
        return ApiPublications.changeSubmitted(publication, author, author.employeeId)
      }
    },
    connectPublication () {
      this.toConnect = { id: null, value: null }
      this.$refs.connectModal.show()
    },
    connectChecked () {
      this.$refs.connectModal.hide()
      this.connectSave()
    },
    connectSave () {
      ApiPublications.connectPublication(this.publication, this.toConnect.id)
        .then(result => {
          this.toConnect = {}
          this.$emit('reload')
        })
        .catch(error => {
          console.log(error)
          this.unexpectedError()
        })
    },
    escConnect () {
      // this.publication.status = 4
      this.statusEvent = null
      this.publication.status = this.originalStatus
      this.toConnect = {}
      this.$refs.connectModal.hide()
    },
    initializeDisciplines () {
      let newItem = {}
      for (let i = 0; i < this.disciplines.length; i++) {
        newItem = {
          id: Number(this.disciplines[i].id),
          science_field_id: this.disciplines[i].science_field_id,
          text: this.disciplines[i].text,
          text_en: this.disciplines[i].text_en
        }
        this.copiedDisciplines.push(newItem)
      }
    },
    initializePublicationDisciplines () {
      if (this.publication && this.publication.disciplines) {
        this.myPublicationDisciplines = []
        this.myPublicationDisciplines.push({ text: 'wybierz', text_en: 'choose', id: '' })
        for (let i = 0; i < this.publication.disciplines.length; i++) {
          this.myPublicationDisciplines.push(this.publication.disciplines[i])
        }
        const disciplinesCount = this.myPublicationDisciplines.length
        if (this.showPublicationAll) {
          for (let i = 0; i < this.copiedDisciplines.length; i++) {
            if (disciplinesCount === 2) {
              if (this.myPublicationDisciplines[1].id !== this.copiedDisciplines[i].id) {
                this.myPublicationDisciplines.push(this.copiedDisciplines[i])
              }
            }
            if (disciplinesCount === 3) {
              if (this.myPublicationDisciplines[1].id !== this.copiedDisciplines[i].id && this.myPublicationDisciplines[2].id !== this.copiedDisciplines[i].id) {
                this.myPublicationDisciplines.push(this.copiedDisciplines[i])
              }
            }
          }
        }
      }
    },
    initializeAuthorDisciplines () {
      if (this.editedAuthor && this.editedAuthor.authorType !== 3) {
        this.myAuthorDisciplines = []
        this.myAuthorDisciplines.push({ text: 'wybierz', text_en: 'choose', id: '' })
        for (let i = 0; i < this.editedAuthor.disciplines.length; i++) {
          this.myAuthorDisciplines.push(this.editedAuthor.disciplines[i])
        }
        const disciplinesCount = this.myAuthorDisciplines.length
        if (this.showAuthorAll) {
          for (let i = 0; i < this.copiedDisciplines.length; i++) {
            if (disciplinesCount === 2) {
              if (this.myAuthorDisciplines[1].id !== this.copiedDisciplines[i].id) {
                this.myAuthorDisciplines.push(this.copiedDisciplines[i])
              }
            }
            if (disciplinesCount === 3) {
              if (this.myAuthorDisciplines[1].id !== this.copiedDisciplines[i].id && this.myAuthorDisciplines[2].id !== this.copiedDisciplines[i].id) {
                this.myAuthorDisciplines.push(this.copiedDisciplines[i])
              }
            }
          }
        }
      }
    },
    changeAuthorShowAll () {
      if (this.editedAuthor && this.editedAuthor.authorType !== 3) {
        for (let i = 0; i < this.editedAuthor.disciplines.length; i++) {
          if (this.editedAuthor.disciplineId === this.editedAuthor.disciplines[i].id || this.editedAuthor.disciplineId === null || isNaN(this.editedAuthor.disciplineId)) {
            this.showAuthorAll = false
          } else {
            this.showAuthorAll = true
          }
        }
      }
      this.initializeAuthorDisciplines()
    },
    checkEditedAuthorStatements () {
      this.editedAuthorCorrect = false
      if (this.editedAuthor && this.editedAuthor.authorType !== 3) {
        for (let i = 0; i < this.editedAuthor.disciplines.length; i++) {
          if (this.editedAuthor.disciplineId === this.editedAuthor.disciplines[i].id || this.editedAuthor.disciplineId === null || isNaN(this.editedAuthor.disciplineId)) {
            this.editedAuthorCorrect = true
          }
        }
      }
    },
    initializeRedactorDisciplines () {
      if (this.editedRedactor && this.editedRedactor.authorType !== 3) {
        this.myRedactorDisciplines = []
        this.myRedactorDisciplines.push({ text: 'wybierz', text_en: 'choose', id: '' })
        for (let i = 0; i < this.editedRedactor.disciplines.length; i++) {
          this.myRedactorDisciplines.push(this.editedRedactor.disciplines[i])
        }
        const disciplinesCount = this.myRedactorDisciplines.length
        if (this.showRedactorAll) {
          for (let i = 0; i < this.copiedDisciplines.length; i++) {
            if (disciplinesCount === 2) {
              if (this.myRedactorDisciplines[1].id !== this.copiedDisciplines[i].id) {
                this.myRedactorDisciplines.push(this.copiedDisciplines[i])
              }
            }
            if (disciplinesCount === 3) {
              if (this.myRedactorDisciplines[1].id !== this.copiedDisciplines[i].id && this.myRedactorDisciplines[2].id !== this.copiedDisciplines[i].id) {
                this.myRedactorDisciplines.push(this.copiedDisciplines[i])
              }
            }
          }
        }
      }
    },
    changeRedactorShowAll () {
      if (this.editedRedactor && this.editedRedactor.authorType !== 3) {
        for (let i = 0; i < this.editedRedactor.disciplines.length; i++) {
          if (this.editedRedactor.disciplineId === this.editedRedactor.disciplines[i].id || this.editedRedactor.disciplineId === null || isNaN(this.editedRedactor.disciplineId)) {
            this.showRedactorAll = false
          } else {
            this.showRedactorAll = true
          }
        }
      }
      this.initializeRedactorDisciplines()
    },
    checkEditedRedactorStatements () {
      this.editedRedactorCorrect = false
      if (this.editedRedactor && this.editedRedactor.authorType !== 3) {
        for (let i = 0; i < this.editedRedactor.disciplines.length; i++) {
          if (this.editedRedactor.disciplineId === this.editedRedactor.disciplines[i].id || this.editedRedactor.disciplineId === null || isNaN(this.editedRedactor.disciplineId)) {
            this.editedRedactorCorrect = true
          }
        }
      }
    },
    initializeDoctorDisciplines () {
      this.myDoctorDisciplines = []
      this.myDoctorDisciplines.push({ text: 'wybierz', text_en: 'choose', id: '' })
      for (let i = 0; i < this.copiedDisciplines.length; i++) {
        this.myDoctorDisciplines.push(this.copiedDisciplines[i])
      }
    },
    checkPublicationStatements () {
      this.fromPublicationStatements = false
      if (this.publication.disciplines && this.publication.disciplines.length > 0) {
        for (let i = 0; i < this.publication.disciplines.length; i++) {
          if (this.publication.disciplines[i].id === this.disciplineId || this.disciplineId === 0 || isNaN(this.disciplineId)) {
            this.fromPublicationStatements = true
          }
        }
      }
      if (!this.fromPublicationStatements) {
        this.showPublicationAll = true
      }
    },
    checkAuthorStatements () {
      this.fromAuthorStatements = []
      if (this.publication.authors && this.publication.authors.length > 0) {
        for (let i = 0; i < this.publication.authors.length; i++) {
          let correct = false
          if (this.publication.authors[i].disciplines && this.publication.authors[i].disciplines.length > 0) {
            for (let j = 0; j < this.publication.authors[i].disciplines.length; j++) {
              if (this.publication.authors[i].disciplines[j].id === this.publication.authors[i].disciplineId || this.publication.authors[i].disciplineId === 0 || isNaN(this.publication.authors[i].disciplineId)) {
                correct = true
              }
            }
          }
          this.fromAuthorStatements.push(correct)
        }
      }
    },
    checkRedactorStatements () {
      this.fromRedactorStatements = []
      if (this.publication.redactors && this.publication.redactors.length > 0) {
        for (let i = 0; i < this.publication.redactors.length; i++) {
          let correct = false
          if (this.publication.redactors[i].disciplines && this.publication.redactors[i].disciplines.length > 0) {
            for (let j = 0; j < this.publication.redactors[i].disciplines.length; j++) {
              if (this.publication.redactors[i].disciplines[j].id === this.publication.redactors[i].disciplineId || this.publication.redactors[i].disciplineId === 0 || isNaN(this.publication.redactors[i].disciplineId)) {
                correct = true
              }
            }
          }
          this.fromRedactorStatements.push(correct)
        }
      }
    },
    publicationPoints (publication) {
      if (publication.alternativePoints) {
        return publication.alternativePoints.toFixed(4) * (10 / 10)
      } else {
        if (publication.epoints > 0) {
          return publication.epoints.toFixed(4) * (10 / 10)
        } else {
          return 0
        }
      }
    },
    authorPoints (author) {
      if (author.epoints > 0) {
        if (author.epoints - Math.floor(author.epoints) !== 0) {
          return author.epoints.toFixed(2)
        } else {
          return author.epoints
        }
      } else {
        return 0
      }
    },
    editPublication () {
      this.$router.push({ name: 'achievement_edit_path', params: { id: this.publication.id } })
    },
    showComments () {
      ApiComments.getComments(this.publication.id)
        .then(result => {
          this.allComments = result.data.datas
          this.allComments.forEach(comment => {
            if (comment.date === comment.editedDate) {
              comment.editedDate = null
            }
            comment.date = this.$d(Date.parse(comment.date), 'long')
            comment.editedDate = comment.editedDate ? this.$d(Date.parse(comment.editedDate), 'long') : null
          })
          this.$bvModal.show(this.publication.id + '-allCommentsModal')
        })
        .catch(error => {
          console.log(error)
        })
    },
    commentAdded (comment) {
      ApiComments.createComment(this.publication.id, comment)
        .then(result => {
          ApiComments.getComments(this.publication.id)
            .then(result => {
              this.allComments = result.data.datas
              this.allComments.forEach(comment => {
                if (comment.date === comment.editedDate) {
                  comment.editedDate = null
                }
                comment.date = this.$d(Date.parse(comment.date), 'long')
                comment.editedDate = comment.editedDate ? this.$d(Date.parse(comment.editedDate), 'long') : null
              })
              this.$bvModal.show(this.publication.id + '-allCommentsModal')
            })
        })
        .catch(error => {
          console.log(error)
        })
    },
    getHistory () {
      this.$bvModal.show(this.publication.id + '-historyModal')
      ApiPublications.getHistory(this.publication.id)
        .then(result => {
          this.history = result.data.datas
          // this.$refs.historyModal.show()
        })
        .catch(error => {
          console.log(error)
        })
    },
    closeHistory () {
      // this.$refs.historyModal.hide()
      this.$bvModal.hide(this.publication.id + '-historyModal')
    },
    changeStatus () {
      if (this.publicationCheckbox) {
        this.$emit('removeUnselected', this.publication.id)
      } else {
        this.$emit('addUnselected', this.publication.id)
      }
    },
    setStatus () {
      if (this.multiCheckbox && this.multiSelect) {
        this.publicationCheckbox = true
      } else if (!this.multiCheckbox) {
        this.publicationCheckbox = false
      }
      if (this.unselectedPublications && this.unselectedPublications.includes(this.publication.id)) {
        this.publicationCheckbox = false
      }
      if (this.selectedPublications && this.selectedPublications.includes(this.publication.id)) {
        this.publicationCheckbox = true
      }
    },
    archivePublication () {
      ApiPublications.archivePublications([this.publication.id], [])
        .then(result => {
          this.showStatusSpinner = true
          setTimeout(() => {
            this.$emit('changePublicationStatus')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          this.showStatusSpinner = false
          this.unexpectedError()
        })
    },
    initializeStatuses () {
      if (!this.orcid) {
        const statuses = []
        const statusesPbn = []
        statuses.push({
          text: this.publication.status, value: null, class: this.publication.status
        })
        statusesPbn.push({
          text: this.publication.statusPbn, value: null, class: this.publication.statusPbn
        })
        this.publication.possibleStatuses.forEach(pe => {
          if (this.hasRight(`event_${pe.event}`)) {
            statuses.push({
              text: pe.status, value: pe.event, class: pe.status
            })
          }
        })
        this.publication.possibleStatusesPbn.forEach(pe => {
          if (this.hasRight(`event_${pe.event}`)) {
            statusesPbn.push({
              text: pe.status, value: pe.event, class: pe.status
            })
          }
        })
        return statuses
      }
    },
    initializeStatusesPbn () {
      const statuses = []
      statuses.push({
        text: this.publication.statusPbn, value: null, class: this.publication.statusPbn
      })
      this.publication.possibleStatusesPbn.forEach(pe => {
        if (this.hasRight(`event_${pe.event}`)) {
          statuses.push({
            text: pe.status, value: pe.event, class: pe.status
          })
        }
      })
      return statuses
    },
    changePublicationStatus () {
      if (this.publication.status === 'draft' && this.statusEvent === 'to_duplicate') {
        this.titleWidth -= 16
      }
      if (this.publication.status === 'draft' && this.statusEvent === 'to_archive') {
        this.titleWidth -= 25.5
      }
      if (this.publication.status === 'draft' && this.statusEvent === 'check') {
        this.titleWidth -= 44
      }
      if (this.publication.status === 'improve' && this.statusEvent === 'check') {
        this.titleWidth -= 11
      }
      if (this.publication.status === 'for_approval' && this.statusEvent === 'to_draft') {
        this.titleWidth += 44
      }
      if (this.publication.status === 'for_approval' && this.statusEvent === 'verify') {
        this.titleWidth -= 10
      }
      if (this.publication.status === 'verified' && this.statusEvent === 'to_improve') {
        this.titleWidth += 18
      }
      if (this.publication.status === 'accepted' && this.statusEvent === 'verify') {
        this.hideStatusPbn = true
      }
      if (this.publication.status === 'verified' && this.statusEvent === 'accept') {
        this.$refs.additionalStatus.show()
      } else {
        if (this.statusEvent === 'to_duplicate') {
          this.connectPublication()
        } else if (this.statusEvent === 'to_archive') {
          this.archivePublication()
        } else {
          ApiPublications.changeStatus(this.publication.id, this.statusEvent)
            .then(result => {
              this.showStatusSpinner = true
              setTimeout(() => {
                this.$emit('changePublicationStatus')
              }, 1000)
              setTimeout(() => {
                this.showStatusSpinner = false
                this.hideStatusPbn = false
              }, 3000)
            })
            .catch(error => {
              console.log(error)
              this.showStatusSpinner = false
              this.hideStatusPbn = false
              this.unexpectedError()
            })
        }
      }
    },
    changePublicationStatusPbn () {
      if (this.publication.statusPbn === 'start' && this.statusPbnEvent === 'approved_pbn') {
        this.titleWidth -= 42
      }
      if (this.publication.statusPbn === 'to_send' && this.statusPbnEvent === 'error_sending_pbn') {
        this.titleWidth -= 11
      }
      if (this.publication.statusPbn === 'to_send' && this.statusPbnEvent === 'sending_manually_pbn') {
        this.titleWidth -= 49
      }
      if (this.publication.statusPbn === 'send_error' && this.statusPbnEvent === 'make_resend_pbn') {
        this.titleWidth -= 46
      }
      if (this.publication.statusPbn === 'send_error' && this.statusPbnEvent === 'sending_manually_pbn') {
        this.titleWidth -= 38
      }
      if (this.publication.statusPbn === 'do_not_send' && this.statusPbnEvent === 'sending_manually_pbn') {
        this.titleWidth -= 44
      }
      if (this.publication.statusPbn === 'sent_manually' && this.statusPbnEvent === 'make_resend_pbn') {
        this.titleWidth -= 9
      }
      if (this.publication.statusPbn === 'sent' && this.statusPbnEvent === 'make_resend_pbn') {
        this.titleWidth -= 68
      }
      ApiPublications.changeStatus(this.publication.id, this.statusPbnEvent)
        .then(result => {
          this.showStatusSpinner = true
          setTimeout(() => {
            this.$emit('changePublicationStatus')
          }, 1000)
          setTimeout(() => {
            this.showStatusSpinner = false
          }, 3000)
        })
        .catch(error => {
          console.log(error)
          this.showStatusSpinner = false
          this.unexpectedError()
        })
    },
    restorePublication () {
      ApiPublications.changeStatus(this.publication.id, 'unarchive')
        .then(result => {
          this.showStatusSpinner = true
          setTimeout(() => {
            this.$emit('changePublicationStatus')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          this.showStatusSpinner = false
          this.unexpectedError()
        })
    },
    exportOrcidPublication () {
      ApiPublications.exportOrcid(this.publication.id, this.tokenOrcid, this.userOrcid)
        .then(result => {
          this.tokenOrcid = null
          this.userOrcid = null
        })
        .catch(error => {
          this.tokenOrcid = null
          this.userOrcid = null
          console.log(error)
          this.$toastr.e(error.response.data.message)
        })
    },
    archivePublicationsModal () {
      this.$refs.archivePublicationConfirm.show()
    },
    restorePublicationsModal () {
      this.$refs.restorePublicationConfirm.show()
    },
    exportOrcidModal () {
      this.$refs.exportOrcidModal.show()
    },
    additionalStatusSave () {
      this.titleWidth -= 180
      this.publication.statusPbn = 'start'
      this.publication.status = 'accepted'
      if (this.additionalStatus === null) {
        this.additionalStatus = 'not_sending_pbn'
      }
      ApiPublications.changeStatus(this.publication.id, this.statusEvent, this.additionalStatus)
        .then(result => {
          this.showStatusSpinner = true
          setTimeout(() => {
            this.$emit('changePublicationStatus')
          }, 1000)
          setTimeout(() => {
            this.showStatusSpinner = false
          }, 3000)
        })
        .catch(error => {
          console.log(error)
          this.showStatusSpinner = false
          this.unexpectedError()
        })
    },
    escAdditionalStatus () {
      this.additionalStatus = null
      this.statusEvent = this.originalStatus
    },
    // editAlternativePoints (author) {
    //   this.pointsErrors = []
    //   this.$refs.changeAlternativePoints.show()
    //   this.changingPointsAuthor = author
    //   if (author.alternativePoints) {
    //     this.alternativePoints = author.alternativePoints
    //   }
    // },
    // changeAuthorAlternativePoints () {
    //   this.authorSpinner = true
    //   const changes = {
    //     employeeId: this.changingPointsAuthor.employeeId,
    //     publicationId: this.publication.id,
    //     alternativePoints: this.alternativePoints
    //   }
    //   ApiEmployeePublications.changeAlternativePoints(changes)
    //     .then(result => {
    //       const idx = this.publication.authors.findIndex(x => x.id === this.changingPointsAuthor.id)
    //       if (idx >= 0) {
    //         this.publication.authors[idx].alternativePoints = Number(this.alternativePoints)
    //       }
    //       this.changingPointsAuthor = null
    //       this.alternativePoints = null
    //       this.authorSpinner = false
    //     })
    //     .catch(error => {
    //       this.pointsErrors = error.response.data
    //       this.authorSpinner = false
    //       this.$refs.changeAlternativePoints.show()
    //     })
    // },
    // changeRedactorAlternativePoints () {
    //   this.redactorSpinner = true
    //   const changes = {
    //     employeeId: this.changingPointsAuthor.employeeId,
    //     publicationId: this.publication.id,
    //     alternativePoints: this.alternativePoints
    //   }
    //   ApiEmployeePublications.changeAlternativePoints(changes)
    //     .then(result => {
    //       const idx = this.publication.redactors.findIndex(x => x.id === this.changingPointsAuthor.id)
    //       if (idx >= 0) {
    //         this.publication.redactors[idx].alternativePoints = Number(this.alternativePoints)
    //       }
    //       this.changingPointsAuthor = null
    //       this.alternativePoints = null
    //       this.redactorSpinner = false
    //     })
    //     .catch(error => {
    //       this.pointsErrors = error.response.data
    //       this.redactorSpinner = false
    //       this.$refs.changeAlternativePoints.show()
    //     })
    // },
    // deleteAuthorAlternativePoints () {
    //   this.$refs.changeAlternativePoints.hide()
    //   this.authorSpinner = true
    //   const changes = {
    //     employeeId: this.changingPointsAuthor.employeeId,
    //     publicationId: this.publication.id,
    //     alternativePoints: null
    //   }
    //   ApiEmployeePublications.changeAlternativePoints(changes)
    //     .then(result => {
    //       const idx = this.publication.authors.findIndex(x => x.id === this.changingPointsAuthor.id)
    //       if (idx >= 0) {
    //         this.publication.authors[idx].alternativePoints = null
    //       }
    //       this.changingPointsAuthor = null
    //       this.alternativePoints = null
    //       this.authorSpinner = false
    //     })
    //     .catch(error => {
    //       this.pointsErrors = error.response.data
    //       this.authorSpinner = false
    //       this.$refs.changeAlternativePoints.show()
    //     })
    // },
    // deleteRedactorAlternativePoints () {
    //   this.$refs.changeAlternativePoints.hide()
    //   this.redactorSpinner = true
    //   const changes = {
    //     employeeId: this.changingPointsAuthor.employeeId,
    //     publicationId: this.publication.id,
    //     alternativePoints: null
    //   }
    //   ApiEmployeePublications.changeAlternativePoints(changes)
    //     .then(result => {
    //       const idx = this.publication.redactors.findIndex(x => x.id === this.changingPointsAuthor.id)
    //       if (idx >= 0) {
    //         this.publication.redactors[idx].alternativePoints = null
    //       }
    //       this.changingPointsAuthor = null
    //       this.alternativePoints = null
    //       this.redactorSpinner = false
    //     })
    //     .catch(error => {
    //       this.pointsErrors = error.response.data
    //       this.redactorSpinner = false
    //       this.$refs.changeAlternativePoints.show()
    //     })
    // },
    authorMouseOver (index) {
      this.authorsHover.splice(index, 1, true)
    },
    authorMouseLeave (index) {
      this.authorsHover.splice(index, 1, false)
    },
    redactorMouseOver (index) {
      this.redactorsHover.splice(index, 1, true)
    },
    redactorMouseLeave (index) {
      this.redactorsHover.splice(index, 1, false)
    }
  },
  watch: {
    '$i18n.locale': function (newLocale, oldLocale) {
      if (oldLocale !== newLocale) {
        this.buildActions()
      }
    },
    publication: {
      deep: true,
      handler () {
        this.initializePublicationDisciplines()
        this.checkAuthorStatements()
        this.checkRedactorStatements()
      }
    },
    difference (newValue, oldValue) {
      if (newValue.length === oldValue.length) {
        let changes = null
        let commentError = false
        for (let i = 0; i < newValue.length; i++) {
          if (newValue[i].text !== oldValue[i].text) {
            if (newValue[i].userId === oldValue[i].userId) {
              changes = newValue[i]
            } else {
              if (this.hasRight('edit_comment')) {
                changes = newValue[i]
              } else if (this.hasRight('edit_own_comment') && this.fullName === newValue[i].author) {
                changes = newValue[i]
              } else {
                changes = newValue[i]
                commentError = true
              }
            }
          }
        }
        if (changes && !commentError) {
          ApiComments.updateComment(changes.id, changes)
            .then(result => {
            })
            .catch(error => {
              console.log(error)
              this.$toastr.e(this.$t('components.publication_element.comments_error'))
            })
        } else if (commentError) {
          this.$toastr.e(this.$t('components.publication_element.comments_error'))
        }
      }
    },
    'publication.status': {
      deep: true,
      handler () {
        // eslint-disable-next-line eqeqeq
        if (this.noRegister == 0) {
          this.pcgOptions.length = 0
          if (this.publication.status === 'draft') {
            if (this.hasRight('edit_achievement') || (this.owner && this.hasRight('edit_own_achievement'))) {
              this.pcgOptions.push({
                text: 'Edytuj',
                href: `/achievement/${this.publication.id}/edit`
              })
            }
          } else if (this.publication.status === 'improve') {
            if ((this.hasRight('edit_achievement') || (this.owner && this.hasRight('edit_own_achievement'))) && (this.hasRight('to_improve_edit') || this.hasRight('out_of_draft_editing'))) {
              this.pcgOptions.push({
                text: 'Edytuj',
                href: `/achievement/${this.publication.id}/edit`
              })
            }
          } else {
            if ((this.hasRight('edit_achievement') || (this.owner && this.hasRight('edit_own_achievement'))) && this.hasRight('out_of_draft_editing')) {
              this.pcgOptions.push({
                text: 'Edytuj',
                href: `/achievement/${this.publication.id}/edit`
              })
            }
          }
          if (this.hasRight('view_achievement') || (this.owner && this.hasRight('view_own_achievement'))) {
            this.pcgOptions.push({
              text: 'Podgląd',
              href: `/achievement/${this.publication.id}/show`
            })
          }
          if (this.hasRight('view_comments')) {
            this.pcgOptions.push({
              text: 'Komentarze',
              action: () => { this.showComments() }
            })
          }
          if (this.hasRight('view_history')) {
            this.pcgOptions.push({
              text: 'Historia publikacji',
              action: () => {
                this.getHistory()
              }
            })
          }
          if (this.hasRight('event_to_archive') && this.publication.status !== 'archive') {
            this.pcgOptions.push({
              text: 'Archiwizuj',
              action: () => { this.archivePublicationsModal() }
            })
          }
          if (this.hasRight('event_unarchive') && this.publication.status === 'archive') {
            this.pcgOptions.push({
              text: 'Przywróć',
              action: () => { this.restorePublicationsModal() }
            })
          }
          if (this.hasRight('export_orcid')) {
            this.pcgOptions.push({
              text: 'Eksportuj do ORCID',
              action: () => { this.exportOrcidModal() }
            })
          }
        }
      }
    },
    editedAuthor: {
      deep: true,
      handler () {
        this.initializeAuthorDisciplines()
        this.checkEditedAuthorStatements()
      }
    },
    editedRedactor: {
      deep: true,
      handler () {
        this.initializeRedactorDisciplines()
        this.checkEditedRedactorStatements()
      }
    },
    disciplines: {
      deep: true,
      handler () {
        this.initializeDoctorDisciplines()
      }
    },
    showPublicationAll: {
      deep: true,
      handler () {
        this.initializePublicationDisciplines()
      }
    },
    showAuthorAll: {
      deep: true,
      handler () {
        this.initializeAuthorDisciplines()
      }
    },
    showRedactorAll: {
      deep: true,
      handler () {
        this.initializeRedactorDisciplines()
      }
    },
    disciplineId: {
      deep: true,
      handler () {
        this.checkPublicationStatements()
      }
    },
    multiSelect: {
      deep: true,
      handler () {
        this.setStatus()
      }
    },
    multiCheckbox: {
      deep: true,
      handler () {
        this.setStatus()
      }
    },
    windowWidth (newValue) {
      this.calculateTitleWidth()
      setTimeout(() => {
        this.calculateTitleWidth()
      }, 100)
    },
    showStatusSpinner: {
      deep: true,
      handler () {
        this.calculateTitleWidth()
      }
    }
  }
}
</script>

<style scoped lang="scss">
    @import '../assets/stylesheets/vars';

    .achievement {
      position: relative;
    }

    .fitted {
        width: fit-content;

        h3 {
            margin: 0;
            margin-right: 10px;
            padding-top: 1px;
            padding-bottom: 1px;
        }
        div {
            padding-right: 10px;
            &.extender {
                display: inline-block;
                width: 13px;
            }
        }

        div:first-of-type {
            padding-left: 0;
            min-width: 81px;
        }

        div:last-of-type {
            padding-right: 0;
            min-width: 48px;
        }
    }

    .duplicates {
        margin-left: -1rem;
        margin-right: -1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
        background-color: $pcg-the-lightest-gray-background;
        border-bottom: 1px solid $pcg-light-gray-background;
        &:first-of-type {
            border-top: 1px solid $pcg-light-gray-background;
            margin-top: 1rem;
        }
    }

    .duplicates-control {
        position: absolute;
        top: 45px;
        right: -30px;
        background-color: $pcg-white;
        color: $main-color;
        border: 2px $pcg-light-gray-background solid;

        &:focus,
        &:hover,
        &:active {
            background-color: $pcg-white;
            color: $main-color;
            border: 2px $pcg-light-gray-background solid;
            box-shadow: none;
        }
    }

    .duplicate-counter {
        color: $pcg-gray;
        font-weight: bold;
        font-size: $font-size-m;
        margin-right: .3rem;
    }

    .long-tooltip{
      ::v-deep .tooltip-inner {
        max-width: 300px;
      }
    }
    .my-arrow {
        margin-right: .3rem;
    }

    .sources {
        span {
            img {
                width: 20px;
                margin-right: 5px;
            }
        }
    }
    .clicked {
        cursor: pointer;
    }
    /*.icon-outer{*/
    /*    width: 40px;*/
    /*    text-align: center;*/
    /*}*/
    .icon {
        width: 15px;
        height: 15px;
        &.circle {
            border-radius: 10px;
            border: 1px solid $pcg-light-gray-background;
        }
        .fas {
            font-size: 1.2rem;
            color: $pcg-dark-gray;
        }
    }
    .periodical {
        h3 {
            display: inline-block;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
            margin-bottom: 0px;
            font-weight: 500;
            color: $main-color;
        }
    }
    .evaluation {
        min-height: 2.14rem;
        /deep/ .pcg-button {
            margin: 0;
        }
    }
    .orange-color {
        color: $pcg-orange;
    }

    .green-color {
        color: $alert-success;
    }
    .title {
        color: $main-color;
        font-weight: 600;
        font-size: $font-size-m;
        letter-spacing: .5px;
    }
    .points {
        display: inline-block;
        padding: 0 3px;
        border-radius: 5px;
        color: $pcg-white;
        min-width: 4rem;
        width: fit-content;
        font-size: $font-size-s;
        position: relative;
        text-align: center;
        font-weight: 400;
        margin-right: .5rem;
    }
    .publication-points {
        position: relative;
        bottom: .05rem;
    }
    .connect-modal {
        font-weight: 400;
        font-size: $font-size-m;
        color: $pcg-gray;
        ::v-deep .pcg-field {
            padding-bottom: 0.375rem;
            padding-top: 0.375rem;
            height: 2rem !important;
        }
        ::v-deep .check-list {
            margin-top: 2rem;
            margin-left: 2.4rem;
            width: 382px !important;
        }
        display: flex;
        justify-content: space-between;
    }
    ::v-deep .pcg-field {
        height: 19.26px !important;
        padding-bottom: 0;
        padding-top: 0;
        line-height: unset;
        color: $main-color;
        font-weight: 400;
    }

    ::v-deep .form-group {
        display: inline-block;
        width: 85%;
        margin: 0 5px;
    }
    ::v-deep .dropdown-menu {
        overflow: auto;
        max-height: 300px;

        li {

            a.dropdown-item {
                padding: .8rem 1.43rem;
                display: flex;
                align-items: center;
            }
        }
    }

    .my-pcg-select {
        /deep/ .pcg-select {
            height: 20px;
            .dropdown-toggle {
                height: 18px;
                padding-bottom: 0;
                padding-top: 0;
                line-height: unset;
            }
        }
        /deep/ .dropdown .filter-option-inner {
            margin-top: -5px;
        }
    }

    /deep/ .modal-header {
        border-bottom: none;
    }

    /deep/ .modal-footer {
        justify-content: space-around;
        border-top: none;
    }

    /deep/ .btn-default {
        color: $main-active-color
    }

    /deep/ .btn-primary {
        border-radius: 30px;
        background-color: $main-active-color;
        padding: .2rem 2rem;
        border: none;
    }

    /deep/ .btn-danger {
        border-radius: 30px;
        background-color: $alert-error;
        padding: .2rem 2rem;
        border: none;
        &:hover, &:focus {
            background-color: darken($alert-error, 25%);
        }
    }

    /deep/ .modal-title {
        color: $pcg-gray;
        font-weight: 400;
        font-size: $font-size-l;
    }

    /deep/ .modal-content {
        padding: 5px 10px;
        border-radius: 10px;
    }

    .first-column {
        width: 50px;
    }

    .my-icon {
        .fas {
            font-size: 1.2rem;
            color: $pcg-dark-gray;
        }
    }

    .oneline {
        margin-bottom: .4rem;
    }

    .pub-type {
        color: $pcg-gray;
        font-size: 1em;
        margin-right: 1rem;
        font-weight: 400;
    }
    .pub-date {
        color: $pcg-gray;
        font-size: 1em;
        font-weight: 400;
        margin-right: 1rem;
    }

    .pub-doi {
        width: 20px;
        height: 20px;
        position: relative;
        bottom: .1rem;
        margin-right: .1rem;
    }
    .added-by-text {
        color: $pcg-gray;
        font-weight: 400;
        font-size: 1em;
    }
    .added-by {
        color: $main-color;
        font-weight: 400;
        text-transform: uppercase;
        margin-right: .4rem;
        font-size: 1em;
    }
    .added-by-register {
        color: $main-color;
        font-weight: 400;
        margin-right: .4rem;
        font-size: 1em;
    }
    .pub-doi-number {
        color: $main-color;
        font-weight: 400;
        font-size: 1em;
        margin-right: 1rem;
        a {
            color: unset;
        }
    }

    .authors {
        position: relative;
        align-items: baseline;
    }
    .authors-list {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: baseline;
    }
    .author {
        font-size: 1em;
        font-weight: 400;
        a {
            font-size: 1em;
            font-weight: 400;
            color: unset;
        }
    }

    .author-text {
        font-size: 1em;
        font-weight: 400;
        color: $pcg-gray;
        margin-right: .5rem;
    }

    .author-full {
        display: inline-block;
        background-color: $pcg-lightest-gray-background;
        padding: 5px 10px !important;
        border-radius: 10px;
        border: 1px solid $pcg-light-gray-background;
        margin-bottom: 5px;

        &.no-discipline-list {
            border-color: $pcg-red;
            color: $pcg-red;
        }

        &.discipline-not-chosen {
            border-color: $pcg-orange;
        }

        &.discipline-chosen {
            border-color: $alert-success;
        }
    }

    .discipline-text {
        font-size: 1em;
        font-weight: 400;
        margin-left: .3rem;
        margin-right: .5rem;
    }

    .my-discipline {
        font-size: 1em;
        font-weight: 400;
        margin-right: 1rem;
    }

    .my-publisher {
        font-size: 1em;
        font-weight: 400;
    }

    .publisher-edit {
        position: relative;
        bottom: .05rem;
        color: $main-active-color;
        font-size: 1.1em;
    }

    .live-search-bar {
        /deep/ .form-group {
            width: 95%;
        }
    }

    .authors-icon {
        position: relative;
        bottom: .2rem;
    }

    /deep/ .check-list {
        color: $pcg-gray;
        font-weight: 400 !important;
        width: 427.5px;

        @media (max-width: 954.98px) {
            width: 380px;
        }
        @media (max-width: 904.98px) {
            width: 332.5px;
        }
        @media (max-width: 854.98px) {
            width: 285px;
        }
        @media (max-width: 804.98px) {
            width: 261.25px;
        }
        @media (max-width: 779.98px) {
            width: 237.5px;
        }
        @media (max-width: 767.98px) {
            width: 427.5px;
        }
        @media (max-width: 614.98px) {
            width: 380px;
        }
        @media (max-width: 564.98px) {
            width: 332.5px;
        }
        @media (max-width: 514.98px) {
            width: 285px;
        }
        @media (max-width: 464.98px) {
            width: 237.5px;
        }
        @media (max-width: 414.98px) {
            width: 190px;
        }

        ul {
            font-size: $font-size-s !important;
            font-weight: 400 !important;
        }

        li {
            padding: .8rem .5rem;
        }
    }

    .my-checkbox {
        position: relative;
        display: inline;
        font-size: $font-size-m;
        /deep/ .pcg-checkbox-container {
            margin-right: 0;
            margin-bottom: 0;
        }
        /deep/ .label {
            margin-bottom: 0;
        }
        /deep/ .pcg-checkmark.normal:after {
            border-color: $alert-success !important;
        }
    }

    .my-exclamation-mark {
        height: 14px;
        width: 14px;
        padding: .2rem .2rem;
        border-radius: 20px;
        text-align: center;
        background-color: $pcg-red;
        margin-left: .3rem;
        position: relative;
        bottom: .1rem;
    }

    .my-exclamation-mark-orange {
        height: 18px;
        width: 18px;
        padding: .2rem .2rem;
        border-radius: 20px;
        text-align: center;
        background-color: $pcg-orange;
        position: relative;
        bottom: .1rem;
    }

    .my-checkbox-text {
        font-size: 1em;
        position: relative;
        bottom: .1rem;
    }

    .authors-checkbox {
        margin-left: .3rem;
        position: relative;
        top: .1rem;
    }

    .submitted {
        position: relative;
        right: .2rem;
    }

    .my-pen {
        margin-left: .5rem;
    }

    .icon-save {
        cursor: pointer;
    }

    .my-image {
        width: 20px;
        margin-right: .2rem;
        margin-left: .1rem;
        position: relative;
        bottom: .1rem;
    }

    .my-mnisw {
        height: 34px;
        margin-right: .5rem;
    }

    .no-discipline {
        font-weight: 400;
        font-size: 1em;
        color: $pcg-red;
    }

    .edit-discipline {
        font-size: $font-size-m;
        font-weight: 400;
        color: $pcg-gray;
        margin-bottom: 1.5rem;
    }

    .my-select {

        /deep/ .dropdown-toggle {
            height: 28px !important;

        }
        /deep/ .bootstrap-select {
            height: 30px !important;
        }
    }

    .download-btn {
        background: none;
        border: none;
        color: $main-active-color;
        padding: 0;
        margin: $pcg-m-xs 0 0 0;
        outline: none;
    }

    .attachments {
        display: inline-block;
        margin-right: 1rem;
    }

    .conference-material {
        margin-left: 1rem;
        color: $pcg-gray;
        font-weight: 400;
    }

    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
        font-weight: 400;
        padding: .5rem;
        border-color: $pcg-light-gray;
        border-style: solid;
        border-width: 1px;
        opacity: 1;
        a {
            color: unset;
        }
    }

    .multi-checkboxes {
        display: inline-block;
        /deep/ .pcg-checkbox-container {
            margin-bottom: 0;
            margin-right: 0;
        }
    }

    .no-checkbox {
      width: 2.2rem;
    }

    /deep/ .arrow::before {
        border-top-color: $pcg-light-gray !important;
    }

    .discipline-select {
        width: 250px;
        @media (max-width: 862.98px) {
            width: 200px;
        }
        @media (max-width: 811.98px) {
            width: 150px;
        }
        @media (max-width: 767.98px) {
            width: 250px;
        }
        @media (max-width: 521.98px) {
            width: 200px;
        }
        @media (max-width: 471.98px) {
            width: 150px;
        }
        @media (max-width: 421.98px) {
            width: 100px;
        }
        /deep/ .dropdown .filter-option-inner {
            color: $pcg-gray;
            font-size: 1em;
        }
        position: relative;
        top: .2rem;
    }

    .monograph-text {
        color: $pcg-gray;
        font-weight: 400;
        font-size: 1em;
    }

    .my-question {
        position: relative;
        bottom: .1rem;
    }

    .publisher-question {
        position: relative;
        bottom: .1rem;
    }

    .publisher-search {
        margin-left: .2rem;
        font-weight: 400;
        font-size: $font-size-m;
        color: $main-color;
        /deep/ .pcg-field {
            height: 2rem !important;
        }
    }

    .show-all-disciplines {
        margin-left: 3rem;
        position: relative;
        top: .1rem;
    }

    .context-menu {
        ::v-deep i {
            font-size: xx-large;
            font-weight: bold;
            position: relative;
            bottom: -5px;
        }
    }

    .hover-publisher {
        position: relative;
        right: 15px;
        padding: 4px 15px 2px;
        width: fit-content;
        &:hover {
            border-radius: 25px;
            background-color: $pcg-lightest-gray-background;
            cursor: pointer;
        }
    }

    .search-text {
        font-weight: 400;
        color: $pcg-gray;
        margin-bottom: .2rem;
    }

    .my-dn-select {
        /deep/ .bootstrap-select .dropdown-toggle {
            height: 2.3rem;
        }
        margin-right: .6rem;
        display: inline-block;
    }

    .wrong-status {
        top: .2rem;
        margin-right: .6rem;
        position: relative;
    }

    .inline-status {
      position: relative;
      top: .3rem
    }

    .inline-pbn-status {
      top: .2rem;
    }

  .action-btn {
    height: 35px;
  }

    .confirm-header {
        font-size: $font-size-m;
        font-weight: 400;
        color: $pcg-gray;
        margin-bottom: 1.5rem;
    }

    .confirm-text {
        font-weight: 400;
        color: $pcg-gray;
        margin-bottom: 1rem;
    }

    .my-input {
        /deep/ .pcg-input {
            height: calc(1.5rem + 0.75rem + 2px) !important;
        }
        /deep/ .pcg-input-label {
            margin-bottom: 0.25rem;
            color: $pcg-gray;
        }
    }

    .points-pen {
        margin-left: .4rem;
        cursor: pointer;
    }

    .my-error {
        color: $pcg-red;
        font-weight: 400;
        margin-left: .3rem;
    }

    .delete-points {
        position: relative;
        top: 1.2rem;
    }

    .icon-class {
        position: relative;
        top: .1rem;
    }

    .main-color {
        color: $main-color;
    }

    .main-active-color {
        color: $main-active-color;
    }

    .main-select-color {
        color: $main-select-color;
    }

    .disabled-color {
        color: $disabled-color;
    }

    .art-kind {
        color: $pcg-gray;
        font-weight: 400;
        margin-right: .1rem;
    }

    .art-detailed-kind {
        color: $pcg-gray;
        font-weight: 400;
    }

    .project-discipline {
        display: inline-block;
        background-color: $pcg-lightest-gray-background;
        padding: 5px 10px !important;
        border-radius: 10px;
        border: 1px solid $pcg-light-gray-background;
        margin-bottom: 5px;
        margin-right: .5rem;
    }

    .project-discipline-name {
        color: $main-color;
    }

    .icon-import {
        position: relative;
        top: .05rem;
        color: $main-color;
    }

    .icon {
        width: 15px;
        height: 15px;
        &.circle {
            border-radius: 10px;
            border: 1px solid $pcg-light-gray-background;
        }
        .fas {
            font-size: 1.2rem;
            color: $pcg-dark-gray;
        }
    }

    .authors-icon {
        position: relative;
        bottom: .1rem;
    }

    .additional-status {
        /deep/ .pcg-select .filter-option-inner {
            position: relative !important;
            bottom: .2rem !important;
        }
    }

    .my-text-input {
        /deep/ .pcg-field {
            font-weight: 400;
            color: $pcg-dark-gray;
            height: 2rem !important;
        }
    }

    .my-label {
        color: $pcg-gray;
        font-weight: 400;
        font-size: $font-size-s;
        margin-left: .3rem;
    }

    .tags-container {
      display: inline-block;
    }

    .tag {
      color: $pcg-dark-gray;
      background-color: $color-active-filter;
      margin-right: 15px;
      margin-bottom: 10px;
      display: inline-block;
      font-size: 0.9rem;
      font-weight: 500;
      line-height: 1rem;
      padding: 8px 13px;
      border-radius: 12px;
      transition: background-color 0.3s;
    }

</style>

<style lang="scss" scoped>
    @import '../assets/stylesheets/vars-contrast';

    .contrast {
      .duplicates {
        background-color: $pcg-the-lightest-gray;
        border-bottom: 1px solid $pcg-light-gray;
        &:first-of-type {
          border-top: 1px solid $pcg-light-gray;
        }
      }

      .duplicates-control {
        background-color: $pcg-white;
        color: $main-color;
        border: 2px $pcg-light-gray solid;

        &:focus,
        &:hover,
        &:active {
          background-color: $pcg-white;
          color: $main-color;
          border: 2px $pcg-light-gray solid;
        }
      }
      .duplicate-counter {
        color: $pcg-gray;
      }
      .icon {
        &.circle {
          border: 1px solid $pcg-light-gray;
        }
        .fas {
          color: $pcg-dark-gray;
        }
      }
      .periodical {
        h3 {
          color: $main-color;
        }
      }
      .orange-color {
        color: $pcg-orange;
      }
      .green-color {
        color: $alert-success;
      }
      .title {
        color: $main-color;
      }
      .points {
        color: $pcg-white;
      }
      .connect-modal {
        color: $pcg-gray;
      }
      ::v-deep .pcg-field {
        color: $main-color;
      }
      /deep/ .btn-default {
        color: $main-active-color
      }
      /deep/ .btn-primary {
        background-color: $main-active-color;
      }
      /deep/ .btn-danger {
        background-color: $alert-error;
        &:hover, &:focus {
          background-color: darken($alert-error, 25%);
        }
      }
      /deep/ .modal-title {
        color: $pcg-gray;
      }
      .my-icon {
        .fas {
          color: $pcg-dark-gray;
        }
      }
      .pub-type {
        color: $pcg-gray;
      }
      .pub-date {
        color: $pcg-gray;
      }
      .added-by-text {
        color: $pcg-gray;
      }
      .added-by {
        color: $main-color;
      }
      .pub-doi-number {
        color: $main-color;
      }
      .author-text {
        color: $pcg-gray;
      }

      .author-full {
        background-color: $pcg-lightest-gray;
        border: 1px solid $pcg-light-gray;

        &.no-discipline-list {
          border-color: $pcg-red;
          color: $pcg-red;
        }

        &.discipline-not-chosen {
          border-color: $pcg-orange;
        }

        &.discipline-chosen {
          border-color: $alert-success;
        }
      }
      .publisher-edit {
        color: $main-active-color;
      }
      /deep/ .check-list {
        color: $pcg-gray;
      }
      .my-checkbox {
        /deep/ .pcg-checkmark.normal:after {
          border-color: $alert-success !important;
        }
      }
      .my-exclamation-mark {
        background-color: $pcg-red;
      }
      .my-exclamation-mark-orange {
        background-color: $pcg-orange;
      }
      .no-discipline {
        color: $pcg-red;
      }
      .edit-discipline {
        color: $pcg-gray;
      }
      .download-btn {
        color: $main-active-color;
      }
      /deep/ .tooltip-inner {
        background: $pcg-white;
        color: $pcg-dark-gray;
        box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
        border-color: $pcg-light-gray;
      }
      /deep/ .arrow::before {
        border-top-color: $pcg-light-gray !important;
      }
      .discipline-select {
        /deep/ .dropdown .filter-option-inner {
          color: $pcg-gray;
        }
      }
      .monograph-text {
        color: $pcg-gray;
      }
      .publisher-search {
        color: $main-color;
      }
      .hover-publisher {
        &:hover {
          background-color: $pcg-lightest-gray;
        }
      }
      .search-text {
        color: $pcg-gray;
      }
      .action-btn {
        /deep/ .pcg-button {
          background: $pcg-the-lightest-gray !important;
          g {
            fill: rgb(0, 153, 65) !important;
          }
        }
        /deep/ .dropdown-item:hover{
          background-color: $main-color !important;
          color: #000;
        }
      }
        .confirm-header {
            color: $pcg-gray;
        }
        .confirm-text {
            color: $pcg-gray;
        }
        .my-input {
            /deep/ .pcg-input-label {
                color: $pcg-gray;
            }
        }
        .my-error {
            color: $pcg-red;
        }
        .main-color {
            color: $main-color;
        }
        .main-active-color {
            color: $main-active-color;
        }
        .main-select-color {
            color: $main-select-color;
        }
        .disabled-color {
            color: $disabled-color;
        }
        .art-kind {
            color: $pcg-gray;
        }
        .art-detailed-kind {
            color: $pcg-gray;
        }
        .project-discipline {
            background-color: $pcg-lightest-gray;
            border: 1px solid $pcg-light-gray;
        }
        .project-discipline-name {
            color: $main-color;
        }
        .icon-import {
            color: $main-color;
        }
        .icon {
            &.circle {
                border: 1px solid $pcg-light-gray;
            }
            .fas {
                color: $pcg-dark-gray;
            }
        }
        .my-text-input {
            /deep/ .pcg-field {
                color: $pcg-dark-gray;
            }
        }
        .my-label {
            color: $pcg-gray;
        }
    }
</style>
